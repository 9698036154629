import React, { useEffect, useState } from "react";
import clsx from "clsx";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";
import { useStoreActions } from "easy-peasy";

import {
  ClassroomFilter,
  DateRangeSelector,
  OrganizationFilter,
  ModuleFilter,
} from "../../custom/forms";

import "./chart-filter.modal.scss";
import { Product } from "../../../models";
import { trackFilterEvent } from "../../../lib/event-trackers";

export default ({
  open,
  onCancel,
  userInfo,
  orgCode,
  onApplyFilters,
  classroomEnabled,
  organizationEnabled,
  moduleEnabled,
  backdropClassName,
  modalClassName,
  product,
  filterCount,
  onToggleAllTime,
}) => {
  const dateRegex = /^(?:\d{2}\/){2}\d{4}$/;

  const { userId, role } = userInfo || {};
  const [dateRange, setDateRange] = useState();
  const [classroom, setClassroom] = useState();
  const [organization, setOrganization] = useState();
  const [module, setModule] = useState();
  const [daysToAdd, setDaysToAdd] = useState();
  const [calendarOpen, setCalendarOpen] = useState();
  const [viewedDates, setViewedDates] = useState({});
  const [notification, setNotification] = useState();

  const { setLastDateRange, setLastDateFilter } = useStoreActions(
    (store) => store.date
  );
  const [modalFilterCount, setModalFilterCount] = useState(filterCount);

  useEffect(() => {
    updateFilterCountHandler();
  }, [classroom, organization, module]);

  const onDateRangeChange = (startDate, endDate, daysToAdd) => {
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    setDaysToAdd(daysToAdd);
    // Workaround to set the date range when when loading the modal
    !open && onApplyFilters({ dateRange });
  };

  const onApply = () => {
    setLastDateRange(dateRange);
    setLastDateFilter(daysToAdd);
    daysToAdd > 365 ? onToggleAllTime(true) : onToggleAllTime();
    trackFilterEvent(dateRange?.startDate, dateRange?.endDate);
    onApplyFilters({ dateRange, classroom, organization, daysToAdd, module });
  };

  const onCancelChartFilter = () => {
    onCancel();
    setCalendarOpen();
  };

  const onSetCalendarOpen = (open) => {
    setCalendarOpen(open);
  };

  const updateFilterCountHandler = () => {
    let count = 1;
    if (classroom) {
      count++;
    }
    if (organization) {
      count++;
    }
    if (module) {
      count++;
    }
    setModalFilterCount(count);
  };

  useEffect(() => {
    if (
      dateRegex.test(viewedDates?.startDate) &&
      dateRegex.test(viewedDates?.endDate)
    ) {
      const start = new Date(viewedDates.startDate);
      const end = new Date(viewedDates.endDate);
      if (end < start) {
        setNotification({
          type: NotificationType.error,
          message: "Date formatted incorrectly.",
          id: "calendar-range-error",
        });
      }
      setNotification();
    } else {
      setNotification({
        type: NotificationType.error,
        message: "Date formatted incorrectly.",
        id: "calendar-format-error",
      });
    }
  }, [viewedDates?.startDate, viewedDates?.endDate]);

  return (
    <Modal
      open={open}
      onClose={onCancelChartFilter}
      backdropClassName={backdropClassName}
      modalClassName={clsx("chart-filter-modal", modalClassName)}
    >
      <ModalHeader
        title={
          <div className="modal-title-badge">
            {"Filters"}
            {modalFilterCount > 0 && (
              <div className="badge">{modalFilterCount}</div>
            )}
          </div>
        }
        icon={["fa-regular", "sliders"]}
      ></ModalHeader>
      <ModalBody>
        <div className="filters-container">
          <div className="filter-container filter-date-range">
            {notification && (
              <Notification
                type={notification.type}
                icon={notification.icon}
                tabIndex={-1}
              >
                {notification.message}
              </Notification>
            )}
            <h3>Date Range</h3>
            <DateRangeSelector
              key="date-range-seletor"
              onDateRangeChange={onDateRangeChange}
              showDivider
              setCalendarOpen={onSetCalendarOpen}
              calendarOpen={calendarOpen}
              setViewedDates={setViewedDates}
            />
          </div>
          {organizationEnabled && (
            <div className="filter-container">
              <h3>Organization</h3>
              <OrganizationFilter
                orgCode={orgCode}
                onOrganizationSelected={setOrganization}
                placeholder="All Organizations"
                product={product}
              />
            </div>
          )}
          {classroomEnabled && (
            <div className="filter-container">
              <h3>Classroom</h3>
              <ClassroomFilter
                userId={userId}
                role={role}
                orgCode={orgCode}
                onClassroomSelected={setClassroom}
                placeholder="All Classrooms"
                product={product}
              />
            </div>
          )}
          {moduleEnabled && product == Product.TS && (
            <div className="filter-container">
              <h3>Module</h3>
              <ModuleFilter
                orgCode={orgCode}
                onModuleSelected={setModule}
                placeholder="All Modules"
                product={product}
                isParentOrg={organizationEnabled}
              />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancelChartFilter}>Cancel</Button>
        <Button onClick={onApply} primary disabled={notification}>
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};
