import React, { useEffect } from "react";

import { DataBoxGroup } from "@transfr-inc/dashboard-components";

const ChartFilter = {
  ActiveExplorers: {
    key: "activeExplorers",
    label: "Active Explorers",
  },
  CareersExplored: {
    key: "careersExplored",
    label: "Careers Explored",
  },
  HighlyRated: {
    key: "highlyRated",
    label: "Careers 4-5 Stars",
  },
};

export function CeDataboxes({ data, onCategoryChange }) {
  const dataBoxConfig = [
    {
      label: ChartFilter.ActiveExplorers.label,
      key: ChartFilter.ActiveExplorers.key,
      alt: "Icon for a user avatar wearing a hardhat.",
      description:
        "Explorers who have started at least one sim during the time period.",
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.ActiveExplorers),
    },
    {
      label: ChartFilter.CareersExplored.label,
      key: ChartFilter.CareersExplored.key,
      alt: "Icon for a checkmark within a circle.",
      description: "Number of careers explorers completed.",
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.CareersExplored),
    },
    {
      label: ChartFilter.HighlyRated.label,
      key: ChartFilter.HighlyRated.key,
      alt: "Icon for a medal.",
      description: "Number of careers that explorers rate as 4 or 5 stars.",
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.HighlyRated),
    },
  ];

  useEffect(() => {
    dataBoxConfig[0].onClick();
  }, []);

  return (
    <DataBoxGroup data={data} dataBoxConfig={dataBoxConfig} className="rows" />
  );
}
