import clsx from "clsx";
import React from "react";

import { TabHeader } from "@transfr-inc/dashboard-components";
import { Product } from "../../products/product";

import "./product-tab-header.scss";

export function ProductTabHeader({
  name,
  onTabClick,
  selected,
  productId,
  text,
  className = "",
  showIcon = false,
}) {
  return (
    <>
      <TabHeader
        name={name}
        onTabClick={onTabClick}
        selected={selected}
        className={clsx(className, "product-tab-header")}
      >
        {showIcon && (
          <Product productId={productId} className="product-tab-header-icon" />
        )}
        <div className="product-tab-header-text">{text}</div>
      </TabHeader>
    </>
  );
}
