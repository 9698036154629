import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ContentType = {
  InHeadset: {
    id: 1,
    key: "inHeadset",
    label: "In Headset",
    icon: () => <FontAwesomeIcon icon="fa-regular fa-head-side-goggles" />,
  },
  OutOfHeadset: {
    id: 2,
    key: "outOfHeadset",
    label: "Out of Headset",
    icon: () => <FontAwesomeIcon icon="fa-regular fa-computer" />,
  },
  Assessment: {
    id: 3,
    key: "assessment",
    label: "Assessment",
    icon: () => <FontAwesomeIcon icon="fa-regular fa-clipboard-list-check" />,
  },
};

export const ContentTypeMap = new Map();
ContentTypeMap.set("INHEADSET", {
  key: ContentType.InHeadset.key,
  contentType: ContentType.InHeadset,
  label: ContentType.InHeadset.label,
  icon: ContentType.InHeadset.icon,
});
ContentTypeMap.set("OUTOFHEADSET", {
  key: ContentType.OutOfHeadset.key,
  contentType: ContentType.OutOfHeadset,
  label: ContentType.OutOfHeadset.label,
  icon: ContentType.OutOfHeadset.icon,
});
ContentTypeMap.set("ASSESSMENT", {
  key: ContentType.Assessment.key,
  contentType: ContentType.Assessment,
  label: ContentType.Assessment.label,
  icon: ContentType.Assessment.icon,
});

export function getContentType(contentType) {
  return ContentTypeMap.get(contentType.key.toUpperCase());
}
export function getContentTypes() {
  return [...ContentTypeMap.values()];
}
export function getContentTypeById(id) {
  return getContentTypes().find(
    (contentType) => contentType.contentType.id === id
  );
}
export function getContentTypeByKey(key) {
  return getContentTypes().find(
    (contentType) => contentType.contentType.key === key
  );
}
export function getContentTypeIconById(id) {
  return getContentTypeById(id).contentType.icon();
}
export const AllContentTypes = Object.values(ContentType);
