import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";
import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import TrackingServiceWrapper from "../components/common/tracking-service-wrapper";

import Panel from "./panel";

import {
  NeedHelp,
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Switch, useRouteMatch } from "react-router-dom";
import { ClientServicesHeader } from "../components/client-services-header";
import { default as DownloadResults } from "../components/common/download-results/download-results";
import { ErrorBoundary } from "../components/error-boundary/error-boundary";
import { RequestProgressModal } from "../components/modals";
import { NavigationBar } from "../components/navbar";
import { Routes, StudentRoutes } from "../lib/routes";

import { default as ContainerRoute } from "../components/common/routing/container-route";
import { Role, hasRole } from "../models/role";
import { RedirectPage } from "../views";

import { useNotificationStack } from "../lib/hooks";
import { initPendo } from "../lib/pendo.init";

import "./app.scss";
import { PRIMARY_TRACKER_ID } from "../config/tracking-config";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 767,
  },
  "medium-panel": {
    minWidth: 768,
    maxWidth: 1024,
  },
  "large-panel": {
    minWidth: 1024,
  },
};

export default function App() {
  const { path } = useRouteMatch();
  const { role, currentUser, impersonating } = useStoreState(
    (store) => store.account
  );

  const { redirect, snowplowCollectorUrl } = useStoreState(
    (store) => store.app
  );

  const applicationRoutes = role == Role.STUDENT.id ? StudentRoutes : Routes;
  const { path: defaultPath } = applicationRoutes[0];

  const impersonationMode = currentUser.impersonation;
  const organizationCode = currentUser.organizationCode;

  const [params, containerRef] = useContainerQuery(query);

  const panelRef = useRef();
  useImperativeHandle(containerRef, () => panelRef.current);

  const {
    notifications,
    closeNotification,
    addNotification,
    closeNotificationTimeout,
  } = useNotificationStack();

  const onSkipNavigation = (e) => {
    e.preventDefault();
    panelRef.current.focus();
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      email: currentUser.email,
      orgCode: organizationCode,
      orgName: currentUser.organizationName,
    });
  }, []);

  useEffect(() => {
    initPendo(currentUser, role, impersonating);
  }, []);

  useEffect(() => {
    if (redirect?.error) {
      const notificationId = addNotification({
        type: NotificationType.error,
        message: "An error occurred, please try again.",
      });

      return closeNotificationTimeout(notificationId);
    }
  }, [redirect]);

  const isStudentExperience = role == Role.STUDENT.id;

  return (
    <div className={clsx("app-layout", impersonationMode && "impersonate")}>
      {impersonationMode && (
        <ClientServicesHeader role={role} currentUser={currentUser} />
      )}
      <div className="app-layout-container">
        <TrackingServiceWrapper
          trackerId={PRIMARY_TRACKER_ID}
          collectorUrl={snowplowCollectorUrl}
          currentUser={currentUser}
        >
          <ErrorBoundary>
            {!isStudentExperience && <NeedHelp />}
            <NavigationBar
              onSkipNavigation={onSkipNavigation}
              isStudentExperience={isStudentExperience}
            ></NavigationBar>
            <Panel ref={panelRef} classNames={clsx("route-container", params)}>
              <Switch>
                {applicationRoutes.map(
                  (route, index) =>
                    !route.isExternalLink &&
                    hasRole(route.roles, role) && (
                      <ContainerRoute
                        key={index}
                        path={`${path}${route.path}`}
                        exact={route.exact}
                        component={route.component}
                        responsiveStyles={params}
                      ></ContainerRoute>
                    )
                )}
                <Route path={`${path}/redirect`}>
                  <RedirectPage></RedirectPage>
                </Route>
                <Route path="*">
                  <Redirect to={`${path}${defaultPath}`} />
                </Route>
              </Switch>
            </Panel>
            <NotificationStack
              className={"app-notification-stack"}
              notifications={notifications}
              onClose={(index) => closeNotification({ index })}
              closable
              animated
            />
            <DownloadResults></DownloadResults>
            <RequestProgressModal />
          </ErrorBoundary>
        </TrackingServiceWrapper>
      </div>
    </div>
  );
}
