import clsx from "clsx";
import React from "react";

import { TabHeader } from "@transfr-inc/dashboard-components";

import "./course-tab-header.scss";

export function CourseTabHeader({
  name,
  onTabClick,
  selected,
  text,
  className = "",
}) {
  return (
    <>
      <TabHeader
        name={name}
        onTabClick={onTabClick}
        selected={selected}
        className={clsx(className, "course-tab-header")}
      >
        <div className="course-tab-header-text">{text}</div>
      </TabHeader>
    </>
  );
}
