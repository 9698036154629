import {
  Loader,
  NotificationStack,
  NotificationType,
  SlashCircleIcon,
} from "@transfr-inc/dashboard-components";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";

import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";
import { useFilter } from "@transfr-inc/dashboard-components/forms";
import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";
import { Product } from "@transfr-inc/dashboard-components/utils";
import ShortcutAction from "../../../components/shortcuts/shortcut.action";
import { SimPassButton } from "../../../components/sim-pass-button";
import container from "../../../container";
import { useNotificationStack } from "../../../lib/hooks";
import { useApiRequest } from "../../../lib/http-client";
import {
  filterClassroomLoginCodesByUserId,
  filterUserLoginCodesByLEId,
} from "../../../services/account.utils";
import { InstructorEmailBox } from "../../users/trainee-detail/components/instructor.email-box";
import { StatusFilter } from "../models/career-status";
import { StatusDropdownList } from "./careers/my-work-ce.status-dropdown-list";
import { MyWorkDataboxes } from "./careers/my-work.ce.databoxes";
import { MyWorkCeSimulationsTable } from "./careers/my-work.ce.simulations-table";

import "./my-work.ce.tab.scss";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 767,
  },
  "medium-panel": {
    minWidth: 768,
    maxWidth: 1080,
  },
  "large-panel": {
    minWidth: 1080,
  },
};

const mergeMasteryData = (simulations, masteryData) => {
  const data = [];
  for (const sim of simulations) {
    const mastery = masteryData.get(sim.learningExperienceId);
    if (mastery) {
      data.push({ ...sim, ...mastery, explored: true });
    } else {
      data.push({ ...sim, explored: false });
    }
  }
  return data;
};

const groupDataByOccupation = (data) => {
  const groupedByOccupation = {};
  for (const item of data) {
    const { occupationCode } = item;
    if (!groupedByOccupation[occupationCode]) {
      groupedByOccupation[occupationCode] = [];
    }
    groupedByOccupation[occupationCode].push(item);
  }
  return groupedByOccupation;
};

export function MyWorkCeTab({ trainee, classroom }) {
  const {
    accountService,
    simulationsService,
    insightsService,
    classroomService,
  } = container;

  const [params, containerRef] = useContainerQuery(query);
  const { isDesktop } = useScreenBreakpoint();
  const [instructors, setInstructors] = useState();
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [dataGroupedByOccupation, setDataGroupedByOccupation] = useState();
  const [masteryStats, setMasteryStats] = useState();
  const [generatingSimPass, setGeneratingSimPass] = useState(false);
  const [deletingSimPass, setDeletingSimPass] = useState(false);
  const [simPass, setSimPass] = useState();
  const [simLoginCodes, setSimLoginCodes] = useState();

  const { response: simulations, loading: loadingSims } = useApiRequest(() =>
    simulationsService.getClassroomSimulations(classroom.classroomId)
  );
  const { response: masteryData, loading: loadingMastery } = useApiRequest(() =>
    insightsService.getUserCEMasteryInfo(trainee?.userId)
  );

  const statusFilter = useFilter({
    data: data,
    queryFn: (data, statusSelected) => {
      if (statusSelected === StatusFilter.All) {
        return data;
      } else if (statusSelected === StatusFilter.CareersExplored) {
        return data.filter((d) => d.explored === true);
      } else if (statusSelected === StatusFilter.CareersNotExplored) {
        return data.filter((d) => d.explored === false);
      } else if (statusSelected === StatusFilter.CareersHighlyRated) {
        return data.filter((d) => d.rating && d.rating >= 4);
      }
    },
  });

  const {
    notifications,
    closeNotification,
    addNotification,
    closeNotificationTimeout,
  } = useNotificationStack();

  const getUserMasteryStats = () => {
    insightsService
      .getExplorerSummaryStats(trainee.userId)
      .then(setMasteryStats)
      .catch(() => setMasteryStats({}));
  };

  const getInstructors = () => {
    classroomService
      .getClassroomInstructors(classroom.classroomId)
      .then(setInstructors)
      .catch(() => setInstructors({}));
  };

  const onStatusSelected = (status) => {
    setSelectedStatus(status);
    const results = statusFilter.queryData(status);
    setFilteredData(results);
  };

  const initData = (value) => {
    setData(value);
    statusFilter.updateData(value);
    setSelectedStatus(StatusFilter.All);
    setFilteredData(statusFilter.queryData(StatusFilter.All));
  };

  useEffect(() => {
    if (simulations && masteryData) {
      const data = mergeMasteryData(simulations, masteryData);
      initData(data);
      const groupedByOccupation = groupDataByOccupation(simulations);
      setDataGroupedByOccupation(groupedByOccupation);
    }
  }, [simulations, masteryData]);

  useEffect(() => {
    if (classroom) {
      getUserMasteryStats();
      getInstructors();
    }
    return () => insightsService.cancelRequest();
  }, []);

  useEffect(() => {
    // retrieve and display any existing, active sim pass
    accountService
      .fetchMostRecentCodes({
        user_id: trainee.userId,
        classroom_id: classroom.classroomId,
        product: Product.CE,
      })
      .then((codes) => {
        const classroomLoginCode = filterClassroomLoginCodesByUserId(
          codes,
          trainee.userId
        );
        const simLoginCodes = filterUserLoginCodesByLEId(codes, trainee.userId);
        setSimPass(classroomLoginCode);
        setSimLoginCodes((v) => ({ ...v, ...simLoginCodes }));
      });
  }, []);

  const generateClassroomLoginCode = async () => {
    setGeneratingSimPass(true);
    try {
      const payload = {
        userIds: [trainee.userId],
        classroomId: classroom.classroomId,
        product: Product.CE,
      };

      const [result] = await accountService.createLoginCodes(payload);
      setSimPass(result);
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setGeneratingSimPass(false);
  };

  const generateSimLoginCode = async (simulation) => {
    let notificationId;
    const { name, learningExperienceId } = simulation;
    try {
      const payload = {
        userIds: [trainee.userId],
        classroomId: classroom.classroomId,
        simulationId: learningExperienceId,
        product: Product.CE,
      };

      const [result] = await accountService.createLoginCodes(payload);

      setSimLoginCodes((value) => ({
        ...value,
        [result.simulationId]: result,
      }));
      notificationId = addNotification({
        type: NotificationType.success,
        message: (
          <>
            Code generated for <strong>{name}</strong>. You have{" "}
            {Math.ceil(result.ttl)} minutes to enter this single use code into
            your headset.
          </>
        ),
      });
    } catch {
      notificationId = addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }

    closeNotificationTimeout(notificationId);
  };

  async function deleteLoginCode(simulationId) {
    setDeletingSimPass(true);
    try {
      const payload = {
        userId: trainee.userId,
        classroomId: classroom.classroomId,
        product: Product.CE,
        simulationId: simulationId ?? "isnull",
      };
      await accountService.deleteCodes(payload);
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setDeletingSimPass(false);
  }

  async function deleteClassroomSimPass() {
    await deleteLoginCode();
    setSimPass();
  }

  async function deleteSpecificSimPass(simulationId) {
    await deleteLoginCode(simulationId);
    delete simLoginCodes[simulationId];
    setSimLoginCodes({ ...simLoginCodes });
  }

  return (
    <>
      {(loadingSims || loadingMastery) && <Loader overlay />}
      <div className="insights-section" ref={containerRef}>
        <div className="top-section">
          <div className="insights-header">
            <div className="title-section">
              <h2 className="title">
                {instructors?.length > 1 || instructors?.length === 0
                  ? "Instructors"
                  : "Instructor"}{" "}
                <span className="badge">{instructors?.length}</span>
              </h2>
            </div>
            <div className="list">
              {!instructors && <div />}
              {instructors?.length > 0 ? (
                instructors.map((instructor) => (
                  <InstructorEmailBox
                    instructor={instructor}
                    key={instructor.userId}
                  ></InstructorEmailBox>
                ))
              ) : (
                <ShortcutAction className="no-data-message" disabled>
                  <SlashCircleIcon></SlashCircleIcon>
                  No instructors assigned to this classroom.
                </ShortcutAction>
              )}
            </div>
          </div>
          <SimPassButton
            classroom={classroom}
            deletingSimPass={deletingSimPass}
            generatingSimPass={generatingSimPass}
            simPass={simPass}
            onClick={generateClassroomLoginCode}
            onDelete={deleteClassroomSimPass}
          />
        </div>
        <div className="insights-header">
          <div className="title-section">
            <h2 className="title">{"Careers"}</h2>
            {!isDesktop && (
              <StatusDropdownList
                className="insights-simulations-filter"
                masteryStats={masteryStats}
                dataGroupedByOccupation={dataGroupedByOccupation}
                onStatusSelected={onStatusSelected}
                selectedStatus={selectedStatus}
                responsiveStyles={params}
              ></StatusDropdownList>
            )}
          </div>
        </div>
        {isDesktop && masteryStats && dataGroupedByOccupation && (
          <MyWorkDataboxes
            className="insights-databoxes"
            masteryStats={masteryStats}
            onStatusSelected={onStatusSelected}
            dataGroupedByOccupation={dataGroupedByOccupation}
            responsiveStyles={params}
          ></MyWorkDataboxes>
        )}
      </div>
      <div className="simulations-section">
        {filteredData && filteredData?.length > 0 && (
          <MyWorkCeSimulationsTable
            responsiveStyles={params}
            simulations={filteredData}
            onCreateCode={generateSimLoginCode}
            loginCodes={simLoginCodes}
            onStartOverSimPass={deleteSpecificSimPass}
            deletingSimPass={deletingSimPass}
          />
        )}
        {filteredData?.length === 0 && (
          <ShortcutAction className="no-data-message" disabled>
            <SlashCircleIcon></SlashCircleIcon>
            No data to display. Please try adjusting your search or filters.
          </ShortcutAction>
        )}
        {!filteredData && (
          <div>
            <Loader height={280}></Loader>
          </div>
        )}
      </div>
      <NotificationStack
        notifications={notifications}
        onClose={(index) => closeNotification({ index })}
        closable
        animated
      />
    </>
  );
}
