import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./lessons-masteries.table.scss";
import {
  BasicCell,
  Column,
  ColumnOptions,
  ColumnType,
  DataTable,
  TimeDataCell,
} from "@transfr-inc/dashboard-components/data-table";
import clsx from "clsx";
import { NavLink, useRouteMatch } from "react-router-dom";

import { RootPath } from "../../../../lib/routes";
import { Link } from "@transfr-inc/dashboard-components";
import {
  ContentType,
  getContentTypeIconById,
} from "../../models/lesson-content-type";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

export default function LessonsMasteriesTable({
  lesson,
  responsiveStyles,
  courseId,
  courseName,
  unitName,
  checkCompleted,
}) {
  const [tableData, setTableData] = useState([]);
  const { url: rootPath } = useRouteMatch(RootPath);

  const columns = [
    new Column(
      "title",
      "Activity",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row, index) => {
          return (
            <div className="activity-title-icon-cell">
              <div
                className={clsx(
                  "activity-icon",
                  `content-type-${row.contentType}`
                )}
              >
                {row?.contentType && getContentTypeIconById(row.contentType)}
              </div>
              <Link
                routerComponent={NavLink}
                className="activity-title"
                to={{
                  pathname: `${rootPath}/activity-player`,
                  state: {
                    breadcrumb: row.breadcrumb,
                    lessonId: row?.lessonId,
                    activityIndex: index,
                    courseId,
                  },
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      })
    ),
    new Column( // Attempts not available for OOH activities
      "attempts",
      "Attempts",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column( // Time not available for OOH activities
      "time",
      "Time",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: TimeDataCell,
      })
    ),
    new Column( // Status not available for OOH activities
      "status",
      "Status",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "completed",
      "Complete",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const formattedDate = row?.completedAt
            ? formatDateDigits(row?.completedAt)
            : undefined;
          return row?.completed ? (
            <div className="completed-cell">
              <>
                <FontAwesomeIcon icon="fa-solid fa-circle-check"></FontAwesomeIcon>
                {formattedDate}
              </>
            </div>
          ) : (
            <BasicCell />
          );
        },
      })
    ),
  ];

  useEffect(() => {
    if (!lesson || !lesson.activities) return;
    const data = lesson.activities.map((activity, i) => {
      const breadcrumb = [
        courseName,
        unitName,
        `Lesson ${i + 1}: ${activity.title}`,
      ].join(",");
      return {
        ...activity,
        status: activity.status,
        time: activity.timeSpent ?? 0,
        attempts: activity.attempts ?? 0,
        completed: checkCompleted(activity) ?? false,
        completedAt: activity.completedAt ?? "",
        lessonId: lesson.id,
        breadcrumb,
      };
    });

    setTableData(data);
  }, [lesson]);

  return (
    <DataTable
      className={clsx("lessons-masteries-table", responsiveStyles)}
      columns={columns}
      data={tableData}
      keyFields={["id"]}
      responsiveStyles={responsiveStyles}
      rowHeightConfig={{ large: 54, medium: 160, small: 280 }}
    />
  );
}
