import HttpClient from "../lib/http-client";

/**
 * @memberof Services
 */
class CourseService extends HttpClient {
  async getCourseList() {
    return this.http.get(`/courses/all`);
  }

  async getCourseDetails(courseId) {
    return this.http.get(`/courses/course/${courseId}`);
  }

  async getLessonDetails(lessonId) {
    return this.http.get(`/courses/lessons/${lessonId}/activities`);
  }
}

export default CourseService;
