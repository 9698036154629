import clsx from "clsx";
import React from "react";

import { DataBox, DataBoxGroup } from "@transfr-inc/dashboard-components";

import { formatTime } from "@transfr-inc/dashboard-components/utils";

import "./classroom.databoxes.scss";

export function TrekClassroomSummaryStats({
  masteryStats,
  trekInsights,
  className,
}) {
  const {
    totalActiveUsers = 0,
    totalPlansSubmitted = 0,
    totalCareersExplored = 0,
  } = trekInsights || {};
  const { totalTimespent = 0 } = masteryStats || {};

  return (
    <div className={clsx("databoxes-section", "ce", className)}>
      <DataBoxGroup>
        <DataBox
          label="ACTIVE EXPLORERS"
          value={totalActiveUsers ?? ""}
          description="Explorers who have logged into Trek and completed the Interest Inventory"
        />
        <DataBox
          label="TOTAL TIME SPENT"
          value={formatTime(totalTimespent) ?? ""}
          description="Time in headset"
        />
        <DataBox
          label="CAREERS EXPLORED"
          value={totalCareersExplored ?? ""}
          description="Number of careers explorers have viewed"
        />
        <DataBox
          label="CAREERS SELECTED"
          value={totalPlansSubmitted ?? ""}
          description="Number of submitted career plans"
        />
      </DataBoxGroup>
    </div>
  );
}
