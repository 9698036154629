import React, { useState } from "react";

import { ProductDropDownList } from "../../../../components/custom/dropdowns/product-dropdown-list";
import { ClassroomProgressTab } from "./classroom.progress.tab";

import { useLocation } from "react-router-dom";
import { useHistoryState } from "../../../../lib/hooks";

import "./classroom.progress.multi.tab.scss";

export const ClassroomProgressMultiProductTab = ({ classroom, users }) => {
  const { selectedProduct } = useLocation()?.state;
  const { products } = classroom;
  const [selectedProductId, setSelectedProductId] = useState(
    selectedProduct ?? products[0]
  );
  const { addStateProps } = useHistoryState();

  return (
    <div className="classroom-progress-multi-product">
      <div className="product-select-container">
        <h2 className="product-select-label">Product</h2>
        <ProductDropDownList
          products={products}
          className="classroom-product-dropdown"
          onProductSelected={({ id }) => {
            setSelectedProductId(id);
            addStateProps({ selectedProduct: id });
          }}
          defaultProduct={selectedProductId}
        ></ProductDropDownList>
      </div>
      <ClassroomProgressTab
        classroom={classroom}
        users={users}
        productId={selectedProductId}
      />
    </div>
  );
};
