import clsx from "clsx";
import React from "react";

import { DataBox, DataBoxGroup } from "@transfr-inc/dashboard-components";

import { formatTime } from "@transfr-inc/dashboard-components/utils";

import "./classroom.databoxes.scss";

export function ClassroomSummaryStats({ masteryStats, className }) {
  const {
    totalTimespent = 0,
    totalExplored = 0,
    totalHighlyRated = 0,
    totalActiveExplorers = 0,
  } = masteryStats || {};

  return (
    <div className={clsx("databoxes-section", "ce", className)}>
      <DataBoxGroup>
        <DataBox
          label="ACTIVE EXPLORERS"
          value={totalActiveExplorers ?? ""}
          description="Number of unique explorers that have started at least 1 career."
        ></DataBox>
        <DataBox
          label="TIME IN HEADSET"
          value={formatTime(totalTimespent) ?? ""}
          description="Amount of time explorers have spent exploring careers."
        ></DataBox>
        <DataBox
          label="CAREERS EXPLORED"
          value={totalExplored ?? ""}
          description="Number of careers explorers completed."
        ></DataBox>
        <DataBox
          label="CAREERS 4-5 STARS"
          value={totalHighlyRated ?? ""}
          description="Number of careers explorers rated 4 or 5 stars."
        ></DataBox>
      </DataBoxGroup>
    </div>
  );
}
