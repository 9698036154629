import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";

import { Button, OrFormDivider } from "@transfr-inc/dashboard-components/forms";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { UserSelect } from "./user-select";
import CreateTraineeModal from "./create-trainee-modal";

import "./trainees-editor.scss";

export function TraineesEditor({
  traineeData,
  onCreateTrainee,
  className,
  userLabels,
  bulkUploadEnabled = false,
  openRosterModal,
}) {
  const { singular, plural } = userLabels;

  const { control } = useFormContext();

  const [showCreateTraineeModal, setShowCreateTraineeModal] = useState(false);
  const [successfullyCreatedTrainee, setSuccessfullyCreatedTrainee] =
    useState();
  const [
    showFileUploadSuccessNotification,
    setShowFileUploadSuccessNotification,
  ] = useState(false);
  const [showFileUploadErrorNotification, setShowFileUploadErrorNotification] =
    useState(false);
  const [fileUploadErrorMessage] = useState();

  const onCreateTraineeModalClose = (trainee) => {
    if (!trainee || trainee.canceled) {
      setSuccessfullyCreatedTrainee();
    } else {
      setSuccessfullyCreatedTrainee(trainee);
    }

    setShowCreateTraineeModal(false);
  };

  return (
    <div className={clsx("trainees-editor", className)}>
      <div
        className={clsx(
          "notifications-area",
          `${
            (showFileUploadErrorNotification ||
              showFileUploadSuccessNotification) &&
            "show-notifications"
          }`
        )}
      >
        {successfullyCreatedTrainee && (
          <Notification
            type={NotificationType.success}
            closable
            onClose={() => {
              setSuccessfullyCreatedTrainee();
            }}
          >
            {successfullyCreatedTrainee.firstName}{" "}
            {successfullyCreatedTrainee.lastName} has been created as an
            trainee.
          </Notification>
        )}
        {showFileUploadSuccessNotification && (
          <Notification
            type={NotificationType.success}
            closable
            onClose={() => {
              setShowFileUploadSuccessNotification();
            }}
            icon="fa-regular fa-head-side-goggles"
          >
            {`Your file has been successfully uploaded. Due to its size this file will ` +
              `continue to process in the background. Feel free to continue and we will ` +
              `send you an email when it's finished. `}
          </Notification>
        )}
        {showFileUploadErrorNotification && (
          <Notification
            type={NotificationType.error}
            closable
            onClose={() => {
              setShowFileUploadErrorNotification();
            }}
          >
            {fileUploadErrorMessage}
          </Notification>
        )}
      </div>
      {bulkUploadEnabled && (
        <>
          <div className="text-with-info">
            <label>
              Quickly create trainees for this classroom using Bulk Roster.
            </label>
          </div>
          <div className="create-trainee-button">
            <Button
              icon={["fa-regular", "clipboard-list-check"]}
              onClick={openRosterModal}
              size="small"
            >
              Bulk Roster
            </Button>
          </div>
          <OrFormDivider />
        </>
      )}

      <div className="create-trainee-button">
        <Button
          primary={false}
          size="small"
          icon={["fa-light", "user-plus"]}
          onClick={() => setShowCreateTraineeModal(true)}
          className="main-dialog"
        >
          Create {singular}
        </Button>
      </div>
      <OrFormDivider />

      <Controller
        control={control}
        name="trainees"
        defaultValue={[]}
        render={({ field: { onChange, value } }) => {
          return (
            <UserSelect
              defaultText={`Add one or more existing ${plural}...`}
              onChange={onChange}
              data={traineeData}
              selectedUsers={value}
              name={"trainees"}
            ></UserSelect>
          );
        }}
      />

      <CreateTraineeModal
        open={showCreateTraineeModal}
        onCreateTrainee={onCreateTrainee}
        onClose={onCreateTraineeModalClose}
        userLabels={userLabels}
      />
    </div>
  );
}
