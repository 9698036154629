/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable prettier/prettier */
import clsx from "clsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import {
  BarOptionDefinition,
  CountText,
  Link,
  Loader,
  Notification,
  OverflowText,
  PlaceholderProducts,
  SlashCircleIcon,
  Toolbar,
} from "@transfr-inc/dashboard-components";
import {
  Column,
  ColumnOptions,
  ColumnType,
  DataTable,
  SortDirection,
  TableCellListContent,
} from "@transfr-inc/dashboard-components/data-table";
import {
  Button,
  SearchBox,
  useSearch,
} from "@transfr-inc/dashboard-components/forms";
import {
  PageHeader,
  PageLayout,
} from "@transfr-inc/dashboard-components/layouts";
import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";
import { useLiveClassroomListCount } from "@transfr-inc/dashboard-sockets";

import { BulkDeleteClassroomModal } from "../../../components/common/bulk-delete-classroom-modal";
import { DemoBadge } from "../../../components/custom/content";
import { ProductDropDownList } from "../../../components/custom/dropdowns/product-dropdown-list";
import { OrganizationFilter } from "../../../components/custom/forms";
import { ShortcutAction } from "../../../components/shortcuts";
import { classroomUrlMap } from "../../../lib/classroom-url-map";
import { ClassroomRoute, RootPath } from "../../../lib/routes";
import { Product } from "../../../models/product";
import { NewClassroomModal } from "../creation/new-classroom-modal";

import { Role } from "../../../models/role";

import "./index.scss";

const populateSortKeys = (data) => {
  for (const classroom of data) {
    classroom.instructorSortKey =
      !classroom.instructors || classroom.instructors.length > 1
        ? classroom.totalInstructors.toString()
        : `${classroom.instructors[0].lastName.toLowerCase()}*${classroom.instructors[0].firstName.toLowerCase()} `;
  }
};

export default function ClassroomList({ responsiveStyles = {}, className }) {
  const { currentUser, role, features, missionControlEnabled } = useStoreState(
    (store) => store.account
  );
  const { classrooms, loadingClassrooms } = useStoreState(
    (state) => state.organization
  );
  const { getClassrooms } = useStoreActions((state) => state.organization);
  const { organizationCode, userId, token } = currentUser;
  const [filteredClassrooms, setFilteredClassrooms] = useState();
  const [cellRefs, setCellRefs] = useState({});
  const [stateNotification, setStateNotification] = useState();

  const { url } = useRouteMatch(`${RootPath}${ClassroomRoute.path}`);
  const { createClassroomEnabled, deleteClassroomEnabled } = features;

  const [showDelete, setShowDelete] = useState(false);
  const [deleteClassroomList, setDeleteClassroomList] = useState([]);
  const [showCreateClassroomModal, setShowCreateClassroomModal] = useState("");

  const availableProducts = useMemo(
    () =>
      [
        ...classrooms.reduce((p, c) => p.union(new Set(c.products)), new Set()),
      ].sort(),
    [classrooms]
  );

  const [filters, setFilters] = useState({
    searchTerm: undefined,
    organization: undefined,
    product: undefined,
    classroomSelected: undefined,
  });

  const nameSearch = useSearch({
    data: [],
    searchKeys: ["name"],
  });

  const addFilter = (key, value) => setFilters((v) => ({ ...v, [key]: value }));

  const onOrganizationChange = (option) => addFilter("organization", option);

  const onResultSelected = (classroomSelected) => {
    addFilter("classroomSelected", classroomSelected);
    addFilter("searchTerm");
  };

  const onNameTermChange = (term) => {
    addFilter("classroomSelected");
    addFilter("searchTerm", term);
  };

  const filterClassrooms = () => {
    const { product, searchTerm, organization, classroomSelected } = filters;
    let filteredClassrooms = classroomSelected
      ? [classroomSelected]
      : classrooms;

    if (organization) {
      filteredClassrooms = filteredClassrooms.filter(
        (c) => c.organizationCode === organization.code
      );
    }

    if (product) {
      filteredClassrooms = filteredClassrooms.filter((c) =>
        c.products.includes(
          product.id === Product.CE_TRK ? Product.CE : product.id
        )
      );
    }

    // Updating source data to ensure only displayed records are searchable
    nameSearch.updateData(filteredClassrooms);

    if (searchTerm) {
      filteredClassrooms = nameSearch.queryData(searchTerm);
    }

    setFilteredClassrooms(filteredClassrooms);
  };

  const launchNotification = async (notification) => {
    setStateNotification(notification);
    setTimeout(() => {
      setStateNotification();
    }, 10000);
  };

  useEffect(() => {
    if (classrooms) {
      populateSortKeys(classrooms);
      createComponentRefs(classrooms);
      nameSearch.updateData(classrooms);
      filterClassrooms();
    }
  }, [classrooms]);

  useEffect(filterClassrooms, [filters]);

  const createComponentRefs = (classes) => {
    const newRefs = { ...cellRefs };
    classes.forEach((classroom) => {
      newRefs[classroom.classroomId] = React.createRef();
    });
    setCellRefs(newRefs);
  };

  const socketUrl = process.env.SOCKET_BRIDGE_URL;
  const missionControlOrgCode = useMemo(
    () =>
      role === Role.MULTI_ORG_ADMIN.id || role === Role.MULTI_ORG_VIEWER.id
        ? filters.organization?.code
        : organizationCode,
    [filters, organizationCode]
  );

  const missionControlClassList = missionControlEnabled
    ? useLiveClassroomListCount(socketUrl, token, missionControlOrgCode)
    : {};

  useEffect(() => {
    const handleUpdate = (classList) => {
      Object.keys(cellRefs).forEach((key) => {
        if (classList[key] && cellRefs[key]?.current?.updateData) {
          const roomCount = classList[key];
          cellRefs[key].current.updateData(roomCount);
        } else if (cellRefs[key] && cellRefs[key]?.current?.updateData) {
          cellRefs[key].current.updateData(0);
        }
      });
    };
    if (Object.keys(missionControlClassList)?.length) {
      handleUpdate(missionControlClassList);
    }
  }, [cellRefs, filteredClassrooms, missionControlClassList]);

  const columns = [
    ...(deleteClassroomEnabled
      ? [new Column("select", /*title:*/ undefined, ColumnType.SELECTION)]
      : []),
    new Column(
      "name",
      "Name",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        sortKeyFields: ["name"],
        customFunction: (value, row) => {
          const path = {
            pathname: `${url}/${classroomUrlMap(row.products)}/${row.code}`,
            state: {
              classroomId: row.classroomId,
              breadcrumbLabel: "All Classrooms",
            },
          };

          return (
            <div className="classroom-name-cell">
              <OverflowText text={value}>
                <Link routerComponent={NavLink} to={path}>
                  {value}
                </Link>
              </OverflowText>
              <DemoBadge isDemo={row.isDemo}></DemoBadge>
            </div>
          );
        },
        isLiveDataColumn: true,
      })
    ),
    new Column(
      "organizationName",
      "organization",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
    new Column(
      "totalInstructors",
      "Instructors",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value, row) => {
          const renderedNames = [];

          if (row.instructors) {
            row.instructors.map((user) => {
              renderedNames.push(`${user.lastName}, ${user.firstName} `);
            });
          }
          return (
            <TableCellListContent
              collection={renderedNames}
              collectionCount={row.totalInstructors}
            />
          );
        },
        sortKeyFields: ["instructorSortKey"],
      })
    ),
    new Column(
      "totalTrainees",
      "Trainees",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "created",
      "Date Created",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => formatDateDigits(value, true),
      })
    ),
    new Column(
      "code",
      "Code",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: true, overflowEnabled: true })
    ),
    new Column(
      "products",
      "Products",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) => {
          return (
            <PlaceholderProducts
              products={value.filter((p) => p !== Product.CE)}
            />
          );
        },
      })
    ),
  ];

  const onDelete = (classroomsToDelete) => {
    setDeleteClassroomList(classroomsToDelete);
    setShowDelete(true);
  };

  const onRowRemoved = () => {
    getClassrooms({ userId, orgCode: organizationCode, role });
    setDeleteClassroomList([]);
  };

  const onClassroomAdded = (notification) => {
    launchNotification(notification);
    setShowCreateClassroomModal();
    notification && getClassrooms({ userId, orgCode: organizationCode, role });
  };

  const bottomBarOptions = [
    new BarOptionDefinition(onDelete, {
      text: "Delete",
      icon: ["fa-light", "fa-trash-can"],
      destructive: true,
    }),
  ];

  const orgFilter = (role === Role.MULTI_ORG_ADMIN.id ||
    role === Role.MULTI_ORG_VIEWER.id) && (
    <OrganizationFilter
      orgCode={organizationCode}
      onOrganizationSelected={onOrganizationChange}
    />
  );

  const toolbar = (
    <Toolbar>
      {createClassroomEnabled && (
        <Button
          icon={["fa-regular", "plus"]}
          primary
          title={"Create Classroom"}
          tooltip={"Create Classroom"}
          onClick={() => setShowCreateClassroomModal(true)}
          size="small"
        ></Button>
      )}
    </Toolbar>
  );

  const pageHeader = (
    <PageHeader
      title={"Classrooms"}
      badge={
        loadingClassrooms ? "..." : <CountText count={classrooms?.length} />
      }
      middlePane={orgFilter}
    >
      {toolbar}
    </PageHeader>
  );

  return (
    <PageLayout
      header={pageHeader}
      responsiveStyles={responsiveStyles}
      className={clsx("classroom-list-page", className, "ce")}
    >
      {loadingClassrooms && <Loader fixed />}
      <div className="filter-section">
        <SearchBox
          placeholder={"Search"}
          query={nameSearch.queryData}
          onSearchTermChange={onNameTermChange}
          onResultSelected={onResultSelected}
          customOptionLabel={(r) => `${r?.name}`}
        ></SearchBox>
        {availableProducts.length > 1 && (
          <ProductDropDownList
            placeholder="Filter by product"
            products={availableProducts}
            clearEnabled
            onProductSelected={(product) => addFilter("product", product)}
            combineCeTrek
          ></ProductDropDownList>
        )}
      </div>
      {classrooms?.length !== 0 && filteredClassrooms?.length === 0 && (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          No data to display. Please try adjusting your search or filters.
        </ShortcutAction>
      )}
      <DataTable
        columns={columns}
        data={filteredClassrooms ?? []}
        className={clsx("classroom-list-data-table")}
        keyFields={["classroomId"]}
        defaultSortPropName="name"
        defaultSortDirection={SortDirection.ASCENDING}
        responsiveStyles={responsiveStyles}
        keyPrefix="classroom"
        bottomBarOptions={deleteClassroomEnabled && bottomBarOptions}
        tableContentName={{ singular: "classroom", plural: "classrooms" }}
        rowHeightConfig={{ large: 54, medium: 150, small: 200 }}
        virtualRowEnabled
        cellRefs={cellRefs}
      />
      {createClassroomEnabled && showCreateClassroomModal && (
        <NewClassroomModal
          show={showCreateClassroomModal}
          onClose={onClassroomAdded}
          orgCode={organizationCode}
          {...features}
        />
      )}
      <BulkDeleteClassroomModal
        onCancel={() => setShowDelete()}
        show={showDelete}
        deleteList={deleteClassroomList}
        onRowRemoved={onRowRemoved}
      />
      <div className="notification-container success-notification">
        {stateNotification && (
          <Notification
            type={stateNotification.type}
            icon={stateNotification.icon}
            onClose={() => {
              setStateNotification();
            }}
            closable
          >
            {stateNotification.message}
          </Notification>
        )}
      </div>
    </PageLayout>
  );
}
