import { saveAs } from "file-saver";
import React, { useEffect, useMemo, useState } from "react";

import {
  Loader,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";
import { formatTimestap } from "@transfr-inc/dashboard-components/utils";

import {
  ClassroomSimPassHeader,
  ClassroomSimPassTable,
} from "../components/sim-pass";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import { Product, SimPassProducts } from "../../../../models";
import { filterClassroomLoginCodesByUserId } from "../../../../services";

import "./classroom.sim-pass.tab.scss";

const mergeUserSimPassData = (users, codesMap = new Map()) => {
  return users?.map((u) => {
    const codeData = codesMap.get(u.userId) ?? {};
    return { ...u, ...codeData };
  });
};

export const ClassroomSimPassTab = ({
  classroom,
  users,
  isTsClassroom,
  responsiveStyles,
}) => {
  const { accountService, classroomService } = container;

  const knownUsers = useMemo(
    () => users.filter((u) => !u.isAnonymous),
    [users]
  );

  const [creatingCodes, setCreatingCodes] = useState();
  const [simPassData, setSimPassData] = useState(knownUsers);
  const [generatingPDF, setGeneratingPDF] = useState();
  const [notification, setNotification] = useState();

  useUnloadWarning({ trigger: generatingPDF });

  const { response: mostRecentCodes, loading } = useApiRequest(() =>
    accountService.fetchMostRecentCodes(
      {
        classroom_id: classroom?.classroomId,
      },
      filterClassroomLoginCodesByUserId
    )
  );

  useEffect(() => {
    if (mostRecentCodes && knownUsers) {
      setSimPassData(
        mergeUserSimPassData(
          knownUsers,
          new Map(Object.entries(mostRecentCodes))
        )
      );
    }
  }, [knownUsers, mostRecentCodes]);

  const onSimPassClick = async (selectedUsers) => {
    try {
      const payload = {
        classroomId: classroom.classroomId,
        userIds: selectedUsers.map((u) => u.userId),
      };

      if (!isTsClassroom) {
        payload.product = SimPassProducts.find((p) =>
          classroom?.products?.some((cp) => p === cp)
        );
      }

      setCreatingCodes(true);
      const response = await accountService.createLoginCodes(payload);
      const userCodeDict = new Map(response.map((r) => [r.userId, r]));
      setSimPassData((values) => mergeUserSimPassData(values, userCodeDict));
      setNotification({
        type: NotificationType.success,
        message: "Codes generated successfully.",
      });
    } catch {
      setNotification({
        type: NotificationType.error,
        message: "An error ocurred while creating codes. Please try again.",
      });
    } finally {
      setCreatingCodes();
    }
  };

  const onExportClick = async () => {
    setGeneratingPDF(true);
    try {
      const product = isTsClassroom ? Product.TS : null;
      const pdfBlob = await classroomService.getSimPassPDF(
        classroom.classroomId,
        product
      );

      const filename = `${
        classroom.name
      } - Sim Pass List - ${formatTimestap()}.pdf`;
      saveAs(pdfBlob, filename);

      setNotification({
        type: NotificationType.success,
        message: "Successfully generated PDF",
        icon: ["fa-solid", "circle-check"],
      });
    } catch {
      setNotification({
        type: NotificationType.error,
        message: "Something went wrong while generating the PDF",
        icon: ["fa-light", "fa-xmark"],
      });
    }
    setGeneratingPDF(false);
  };

  useEffect(() => {
    if (notification) {
      const timoutId = setTimeout(() => setNotification(), 3000);
      return () => clearTimeout(timoutId);
    }
  }, [notification]);

  return (
    <div className="classroom-sim-pass-tab">
      <ClassroomSimPassHeader
        generatingExport={generatingPDF}
        onExportClick={onExportClick}
      />
      <div className="sim-pass-table-container">
        {creatingCodes && <Loader overlay />}
        {notification && (
          <Notification
            type={notification.type}
            closable
            animated
            onClose={() => setNotification()}
          >
            {notification.message}
          </Notification>
        )}
        {loading ? (
          <Loader />
        ) : (
          <ClassroomSimPassTable
            data={simPassData}
            onSimPassClick={onSimPassClick}
            responsiveStyles={responsiveStyles}
          />
        )}
      </div>
      <div className="update-notification-container">
        {generatingPDF && (
          <Notification
            type={NotificationType.info}
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            <div className="update-message">Generating PDF...</div>
          </Notification>
        )}
        {notification && (
          <Notification
            type={notification.type}
            icon={notification.icon}
            closable
            onClose={() => setNotification()}
          >
            {notification.message}
          </Notification>
        )}
      </div>
    </div>
  );
};
