import React, { useState } from "react";

import "./ooh-activity-view.scss";

const OohContent = ({ oohLaunchParameters, data }) => {
  const oohLaunchUrl = `${data.hosted_content_path}?slxapi=${encodeURIComponent(
    JSON.stringify(oohLaunchParameters)
  )}`;

  console.log({ oohLaunchUrl });

  return (
    <div className="activity-player-ooh-content">
      <iframe src={oohLaunchUrl}></iframe>
    </div>
  );
};

export default OohContent;
