import React from "react";
import { InstructorEmailBox } from "../../../users/trainee-detail/components/instructor.email-box";
import "./course-top-section.scss";

export function CourseTopSection({ course }) {
    const { instructors } = course ?? [];
  return (
    <div className="course-top-section">
      <div className="course-info-instructors">
        <div className="title-section">
          <h2 className="title">
            {instructors?.length > 1 || instructors?.length === 0 || !instructors
              ? "Instructors"
              : "Instructor"}{" "}
            <span className="badge">{instructors?.length || 0}</span>
          </h2>
        </div>
        <div className="list">
          {!instructors && <div />}
          {instructors?.length > 0 ? (
            instructors.map((instructor) => (
              <InstructorEmailBox
                instructor={instructor}
                key={instructor.userId}
              ></InstructorEmailBox>
            ))
          ) : (
              <span>{"No instructors."}</span>
          )}
        </div>
      </div>
      <div className="course-info-description">
        <div className="title-section">
          <h2 className="title">Description</h2>
        </div>
        <div className="description">
          {course?.description || "No description."}
        </div>
      </div>
    </div>
  );
}
