import React from "react";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { saveAs } from "file-saver";
import "./upload-footer.scss";

const TEMPLATE_DOWNLOAD_INFO_TEXT =
  `Please download and fill out the trainee template. Once completed press Bulk Upload and locate your file. Any ` +
  `trainees not currently in the system will be created. ` +
  `Larger uploads will continue to process in the background. We will send you an email when the file is finished.`;

const downloadBulkUploadTemplate = () => {
  saveAs(
    `/templates/bulk-user-upload-template.csv`,
    "bulk-user-upload-template.csv"
  );
};

export function UploadFooter({ setOpenRosterModal }) {
  return (
    <div className="student-rostering">
      <div className="roster-container">
        <Button
          icon={["fa-regular", "clipboard-list-check"]}
          onClick={() => setOpenRosterModal(true)}
          size="small"
        >
          Roster
        </Button>
        <div className="message">
          Use Roster to quickly create trainees across your organization.
        </div>
      </div>
    </div>
  );
}
