import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Loader,
  Notification,
  NotificationType,
  Toolbar,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import {
  useDrawerBorder,
  useDrawerColor,
} from "@transfr-inc/dashboard-components/hooks";
import {
  PageHeader,
  PageLayout,
} from "@transfr-inc/dashboard-components/layouts";
import { Color } from "@transfr-inc/dashboard-components/utils";

import {
  ChangePasswordModal,
  ChangeProfileAvatarModal,
} from "../../components/modals";
import { EditProfileModal } from "./edit-profile-modal";
import { ProfileInformation } from "./profile-information";
import { ResetPasswordModal } from "./reset-password-modal";

import container from "../../container";
import { useApiRequest } from "../../lib/http-client";
import { Role } from "../../models";
import { LOGOUT_TYPES } from "../../models/tracking/event-types";
import { trackView } from "../../lib/event-trackers";
import { ViewProfile } from "../../models/tracking/events";

import "./profile.scss";

export default () => {
  const { userService, currentUserCache } = container;
  const history = useHistory();
  const { logout } = useStoreActions((state) => state.account);
  const { currentUser, role } = useStoreState((store) => store.account);

  const [user, setUser] = useState(currentUser);
  const [notification, setNotification] = useState();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showChangeProfileAvatar, setShowChangeProfileAvatar] = useState(false);

  const { toggleColor, revertColor } = useDrawerColor({
    bgColor: Color.navBarGrey,
  });
  const { toggleBorder, revertBorder } = useDrawerBorder();
  const { loading: loadingUser } = useApiRequest(() =>
    userService.getUserDetails(currentUser.userId).then(setUser)
  );

  const onEditUser = (updatedUser) => {
    setUser({ ...user, ...updatedUser });
    showNotification({
      type: NotificationType.success,
      message: `Profile succesfully updated`,
    });
    setShowEditProfile();
  };

  const onEditAvatar = (updatedUser) => {
    setUser({ ...user, ...updatedUser });
    setShowChangeProfileAvatar();
  };

  const onEditNotificationPreferences = (notificationPreferences) => {
    // This allows us to force uncontrolled components to properly reflect the
    // state when something goes wrong.
    const previousPreferences = user.notificationPreferences;

    // We do an optimistic update because uncontrolled components (e.g.,
    // ToggleButton) have already updated at this point and we can't affect
    // their internal state unless we match it first.
    setUser({ ...user, notificationPreferences });

    userService
      .updateUserNotificationPreferences(user.userId, notificationPreferences)
      .then(() =>
        showNotification({
          type: NotificationType.success,
          message: "Notification preferences successfully updated",
        })
      )
      .catch(() => {
        setUser({ ...user, notificationPreferences: previousPreferences });
        showNotification({
          type: NotificationType.error,
          message: "Unable to update notification preferences",
        });
      });
  };

  const onResetPassword = () => {
    setShowResetPassword();
    const successMessage =
      role == Role.STUDENT.id
        ? `Password successfully changed.`
        : `Check your email to finish resetting your password.`;

    showNotification({
      type: NotificationType.success,
      message: successMessage,
    });
  };

  const showNotification = ({ type, message }) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification();
    }, 10000);
  };
  const signOutHandler = () => {
    const logoutPayload = {
      logOutRequested: true,
      logoutType: LOGOUT_TYPES.CORE_PLATFORM,
    };
    const userCache = currentUserCache.get();
    if (userCache.impersonatorId) {
      logoutPayload.logoutType = LOGOUT_TYPES.IMPERSONATION;
      logoutPayload.impersonatorId = userCache.impersonatorId;
    }

    logout(logoutPayload);
    history.push("/login");
  };

  useEffect(() => {
    toggleColor();
    return () => revertColor();
  }, []);

  useEffect(() => {
    toggleBorder();
    return () => revertBorder();
  }, []);

  useEffect(() => {
    trackView(ViewProfile, "Profile");
  }, []);

  const pageHeader = (
    <PageHeader title="Profile">
      {role != Role.STUDENT.id ? (
        <Toolbar>
          <Button
            icon="fa-regular fa-edit"
            onClick={() => setShowEditProfile(true)}
            title="Edit Profile"
            tooltip="Edit Profile"
            size="small"
          ></Button>
          <Button
            primary
            icon="fa-regular fa-door-open"
            onClick={signOutHandler}
            title="Sign Out"
          >
            Sign Out
          </Button>
        </Toolbar>
      ) : (
        <Button
          primary
          icon="fa-regular fa-door-open"
          onClick={signOutHandler}
          title="Sign Out"
        >
          Sign Out
        </Button>
      )}
    </PageHeader>
  );

  return (
    <>
      {loadingUser && <Loader overlay />}
      <PageLayout className="profile-page" header={pageHeader}>
        <div className="profile-body">
          <ProfileInformation
            onResetClick={setShowResetPassword}
            onChangePhotoClick={setShowChangeProfileAvatar}
            onEditNotificationPreferences={onEditNotificationPreferences}
            user={user ?? {}}
          />
          {!loadingUser && (
            <>
              <EditProfileModal
                onCancel={() => setShowEditProfile()}
                show={showEditProfile}
                user={user}
                onEditUser={onEditUser}
              />
              <ChangeProfileAvatarModal
                onCancel={() => setShowChangeProfileAvatar()}
                onEditAvatar={onEditAvatar}
                user={user}
                show={showChangeProfileAvatar}
              />
              {role == Role.STUDENT.id ? (
                <ChangePasswordModal
                  key="toolbar-password-trainee-modal"
                  open={showResetPassword}
                  userIds={[user.userId]}
                  onCancel={() => setShowResetPassword()}
                  onPasswordChanged={onResetPassword}
                ></ChangePasswordModal>
              ) : (
                <ResetPasswordModal
                  onCancel={() => setShowResetPassword()}
                  onResetPassword={onResetPassword}
                  show={showResetPassword}
                  userId={user.userId}
                  email={user.email}
                />
              )}
            </>
          )}
        </div>
        <div className="notification-container">
          {notification && (
            <Notification
              type={notification.type}
              icon={notification.icon}
              onClose={() => {
                setNotification();
              }}
              closable
            >
              {notification.message}
            </Notification>
          )}
        </div>
      </PageLayout>
    </>
  );
};
