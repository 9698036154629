import React, { useEffect, useState } from "react";
import { useApiRequest } from "../../../../lib/http-client";
import container from "../../../../container";
import { Loader } from "@transfr-inc/dashboard-components";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";
import "./course.tab.scss";
import { CourseUnitAccordion } from "./course-unit-accordion";
import { useStoreState } from "easy-peasy";
import { CourseTopSection } from "./course-top-section";
import { UnitLessonAccordion } from "./unit-lesson-accordion";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 850,
  },
  "medium-panel": {
    minWidth: 700,
    maxWidth: 900,
  },
  "large-panel": {
    minWidth: 900,
  },
};

export function CourseTab({ id, name }) {
  const { courseService, insightsService } = container;
  const [courseData, setCourseData] = useState();
  const [params, containerRef] = useContainerQuery(query);

  const { response: data, loading: loadingCourseData } = useApiRequest(() =>
    courseService.getCourseDetails(id).then(setCourseData)
  );

  const { currentUser } = useStoreState((store) => store.account);
  const { userId } = currentUser;
  const [masteryScores, setMasteryScores] = useState("");
  const [masteryOOHScores, setMasteryOOHScores] = useState("");

  const { loading: loadingCourseMastery, response: courseMastery } =
    useApiRequest(() => insightsService.getCourseMastery(userId, id));

  const mapMasteries = (courseMastery) => {
    if (!courseMastery) return null;
    let ihMastery = courseMastery?.ih_masteries;
    let oohMastery = courseMastery?.ooh_masteries;
    if (ihMastery) {
      let ihMasteries = {};
      ihMastery.map((mastery) => {
        ihMasteries[mastery["learning_experience_id"]] =
          mastery["mastery_score"];
      });
      setMasteryScores(ihMasteries);
    } else {
      setMasteryScores(null);
    }

    if (oohMastery) {
      let oohMasteries = {};
      oohMastery.map((mastery) => {
        oohMasteries[mastery["object_id"]] = mastery["latestTimestamp"];
      });
      setMasteryOOHScores(oohMasteries);
    } else {
      setMasteryOOHScores(null);
    }
  };

  const checkCompleted = (activity) => {
    if (
      masteryScores &&
      activity.simulation &&
      activity.simulation_id in masteryScores
    ) {
      activity.masteryScore = masteryScores[activity.simulation_id];
      return true;
    } else if (
      masteryOOHScores &&
      !activity.simulation &&
      activity.id in masteryOOHScores
    ) {
      activity.completedAt = masteryOOHScores[activity.id];
      return true;
    }
    return false;
  };

  useEffect(() => {
    mapMasteries(courseMastery);
  }, [courseMastery]);

  const unitsComponent = () => {
    return (
      <div className="course-units-container">
        {courseData?.units?.map((unit, index) => (
          <CourseUnitAccordion
            key={unit.id}
            unit={unit}
            index={index + 1}
            responsiveStyles={params}
            isExpanded={index === 0}
            courseId={id}
            courseName={courseData.title}
            checkCompleted={checkCompleted}
          />
        ))}
      </div>
    );
  };

  const lessonsComponent = () => {
    return (
      <div>
        {courseData?.lessons?.map((lesson, index) => (
          <UnitLessonAccordion
            key={lesson.id}
            lesson={lesson}
            index={index + 1}
            responsiveStyles={params}
            courseId={id}
            courseName={courseData.title}
            unitName={courseData.title}
            checkCompleted={checkCompleted}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      {loadingCourseData && <Loader overlay />}
      <div>
        <CourseTopSection course={courseData} />
      </div>
      <div className="course-content-container" ref={containerRef}>
        {courseData?.units && unitsComponent()}
        {courseData?.lessons && lessonsComponent()}
      </div>
    </>
  );
}
