import HttpClient from "../lib/http-client";
import { getStatusValue, MasteryStatus } from "../models/mastery";

/**
 * @memberof Services
 */
class InsightsService extends HttpClient {
  async getModulesForOrg(orgCode, isParentOrg = false, classroomId) {
    return isParentOrg
      ? this.getModulesForMultiOrg(orgCode)
      : this.getModulesForSingleOrg(orgCode, classroomId);
  }

  async getModulesForSingleOrg(organizationCode, classroomId) {
    let url = classroomId
      ? `insights/client/${organizationCode}/module/simulations?classroom_id=${classroomId}`
      : `insights/client/${organizationCode}/module/simulations`;
    return this.http.get(url).then(addIndexToSims);
  }

  async getModulesForMultiOrg(organizationCode) {
    return this.http
      .get(`insights/client/parent/${organizationCode}/module/simulations`)
      .then(addIndexToSims);
  }

  async getUserMasteryStats(userId, loIdList) {
    return this.http
      .post(`/insights/mastery/user/${userId}/stats`, { loIdList })
      .then((response) => response[0]);
  }

  async getUserMasteryInfo(userId) {
    return this.http
      .get(`/insights/mastery/user/${userId}/results`)
      .then(setStatusValue)
      .then((response) => new Map(response.map((r) => [r.objectId, r])))
      .catch((error) => {
        console.error(`[Insights Service] - An error occured: ${error}`);
        return new Map();
      });
  }

  async getUserCEMasteryInfo(userId) {
    return this.http
      .get(`/insights/ce/mastery/user/${userId}/results`)
      .then(
        (response) => new Map(response.map((r) => [r.learningExperienceId, r]))
      )
      .catch((error) => {
        console.error(`[Insights Service] - An error occured: ${error}`);
        return new Map();
      });
  }

  async getCEUserTopCareers(userId) {
    return this.http
      .get(`/insights/ce/user/${userId}/top-careers`)
      .catch((error) => {
        console.error(`[Insights Service] - An error occured: ${error}`);
        return [];
      });
  }

  async getCEClassroomTopCareers(userId) {
    return this.http
      .get(`/insights/ce/classroom/${userId}/top-careers`)
      .catch((error) => {
        console.error(`[Insights Service] - An error occured: ${error}`);
        return [];
      });
  }

  async getCeClassroomExplorersMastery(classroomId) {
    return this.http.get(`/insights/ce/classroom/${classroomId}/users/stats`);
  }

  async getClassroomMasteryInfo(classroomId) {
    return this.http
      .get(`/insights/mastery/classroom/${classroomId}/results`)
      .then(setStatusValue)
      .then((response) => createSimsMasteryMap(response));
  }

  async getUserModulesWithMasteryInfo(orgCode, userId, isStudent, classroomId) {
    const modules = await this.getModulesForOrg(
      orgCode,
      false,
      isStudent ? classroomId : undefined
    );
    const masteryInfo = classroomId
      ? await this.getUserMasteryInfo(userId)
      : new Map();
    const modulesWithMasteryInfo = [];

    for (const m of modules) {
      modulesWithMasteryInfo.push(mergeMasteryStats(m, masteryInfo));
    }

    return modulesWithMasteryInfo;
  }

  async getClassroomMasteryStats(classroomId, moduleId, objectId) {
    return this.cachedRequest(
      `/insights/mastery/classroom/${classroomId}/stats`,
      {
        params: {
          moduleId,
          objectId,
        },
      }
    ).then((response) => (Array.isArray(response) ? undefined : response));
  }

  async getClassroomCeSummaryStats(classroomId) {
    return this.http.get(`/insights/ce/classroom/${classroomId}/stats`);
  }

  async getClassroomCeResults(
    classroomId,
    { includeOccupationCodes, ...params } = {}
  ) {
    const results = await this.http.get(
      `/insights/ce/classroom/${classroomId}/results`,
      { params: { includeOccupationCodes, ...params } }
    );

    const masteryMap = new Map();
    for (const r of results) {
      const masteryKey = includeOccupationCodes
        ? r.occupationCode
        : r.learningExperienceId;
      const result = masteryMap.get(masteryKey) ?? new Map();
      result.set(r.userId, r);
      masteryMap.set(masteryKey, result);
    }
    return masteryMap;
  }

  async getClassroomTraineesMastery(classroomId) {
    return this.http.get(
      `/insights/mastery/classroom/${classroomId}/trainee/stats`
    );
  }

  async getExplorerSummaryStats(userId) {
    return this.http.get(`/insights/ce/user/${userId}/stats`);
  }

  exportExplorerData(orgCode, classroomId, userId, fileName) {
    const config = {
      method: "get",
      url: `/insights/ce/client/${orgCode}/classroom/${classroomId}/user/${userId}/results/export`,
    };
    return this.downloadBlob(config, fileName, "csv");
  }

  exportTraineeData(orgCode, userId, fileName) {
    const config = {
      method: "get",
      url: `/insights/mastery/client/${orgCode}/user/${userId}/results/export`,
    };
    return this.downloadBlob(config, fileName, "csv");
  }

  exportClassroomData(classroomId, orgCode, fileName, moduleId) {
    if (moduleId) {
      const requestConfig = {
        method: "get",
        url: `/insights/mastery/module/${moduleId}/classroom/${classroomId}/export`,
      };
      return this.downloadBlob(requestConfig, fileName);
    }
    const requestConfig = {
      method: "get",
      url: `/insights/mastery/client/${orgCode}/classroom/${classroomId}/results/export`,
    };
    return this.downloadBlob(requestConfig, fileName);
  }

  exportCeClassroomData(classroomId, orgCode, fileName) {
    const requestConfig = {
      method: "",
      url: `/insights/ce/client/${orgCode}/classroom/${classroomId}/results/export`,
    };
    return this.downloadBlob(requestConfig, fileName);
  }

  async getUserSimulationMastery(userId, moduleId, simulationId) {
    let resp = await this.http.get(
      `/insights/mastery/user/${userId}/module/${moduleId}/simulation/${simulationId}`
    );
    return resp;
  }

  async getAllUserSimulationMastery(userId) {
    let resp = await this.http.get(`/insights/mastery/user/${userId}/all`);
    return resp;
  }

  async getCourseMastery(userId, courseId) {
    let resp = await this.http.get(
      `/insights/mastery/user/${userId}/course/${courseId}`
    );
    return resp;
  }
}

function setStatusValue(masteryStats) {
  for (const stats of masteryStats) {
    stats.status = getStatusValue(stats.status);
    stats.mastery =
      stats.status === MasteryStatus.NotStarted ? 0 : stats.mastery;
  }
  return masteryStats;
}

function mergeMasteryStats(module, masteryStats) {
  if (masteryStats.size === 0) {
    return module;
  }

  module.simulations = module.simulations.map((s) => {
    const mastery = masteryStats.get(s.learningObjectiveId);
    return mastery ? { ...s, ...mastery } : s;
  });

  return module;
}

function createSimsMasteryMap(masteryInfo) {
  const modulesMap = new Map();

  for (const m of masteryInfo) {
    const simsMap = modulesMap.get(m.moduleId) ?? new Map();
    const traineeMap = simsMap.get(m.objectId) ?? new Map();

    traineeMap.set(m.userId, m);
    simsMap.set(m.objectId, traineeMap);
    modulesMap.set(m.moduleId, simsMap);
  }

  return modulesMap;
}

function addIndexToSims(modules) {
  return modules.map((module) => ({
    ...module,
    simulations: module.simulations.map((sim, index) => ({
      ...sim,
      index: index + 1,
    })),
  }));
}

export default InsightsService;
