import React, { useState, useEffect, useMemo } from "react";
import { MissionControlTiles } from "./mission-control-tiles";
import { Tile } from "@transfr-inc/dashboard-components";

import { Button, ToggleButton } from "@transfr-inc/dashboard-components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";

import clsx from "clsx";

import "./mission-control-container.scss";

export const MissionControlContainer = ({
  classroom,
  users,
  fullScreen,
  setFullScreen,
  missionControlDataArray,
  loading,
  showZero,
  isConnected,
  showLiveFeed,
  onToggleLiveFeed,
  setSpotlightTile,
  spotlightTile,
  imageData,
  handleCloseFullScreen,
}) => {
  const [fadeIn, setFadeIn] = useState();

  useEffect(() => {
    if (spotlightTile?.id) {
      setFadeIn(true);
      setTimeout(() => setFadeIn(), 1000);
    }
  }, [spotlightTile?.id]);

  const onFullScreenHandler = () => {
    setFullScreen(true);
  };

  return (
    <>
      <div className="tiles-main-container">
        <div
          className={clsx(
            "mission-control-header",
            fullScreen && "full-screen-header"
          )}
        >
          {fullScreen ? (
            <div className="classroom-info">
              <div className="classroom-name">{classroom?.name}</div>
              <div className="mission-control-title-container">
                <div className="mission-control-title">Mission Control</div>
                <div className="mission-control-badge">
                  {missionControlDataArray?.length}
                </div>
              </div>
            </div>
          ) : (
            <Button onClick={onFullScreenHandler} disabled={false}>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Full Screen
            </Button>
          )}
          <div className="toggle-live-feed-container">
            <div className="toggle-live-feed">
              <div className="description">Image Feed</div>
              <ToggleButton
                onChange={onToggleLiveFeed}
                value={showLiveFeed}
                disabled={!isConnected}
              />
            </div>
            {fullScreen && (
              <Button
                icon={["fa-regular", "xmark"]}
                onClick={handleCloseFullScreen}
              ></Button>
            )}
          </div>
        </div>
        <div className={clsx("tiles-body", fullScreen && "full-screen-body")}>
          <div
            className={clsx(
              "mission-control-main-container",
              !!spotlightTile && "spotlight-mode"
            )}
          >
            {!!spotlightTile && (
              <div
                className={clsx(
                  "spotlight-tile-container",
                  fadeIn && "fade-in"
                )}
              >
                <Tile
                  isSelected
                  className="spotlight-tile"
                  firstName={spotlightTile.firstName}
                  lastName={spotlightTile.lastName}
                  completionPercentage={spotlightTile.completionPercentage}
                  status={spotlightTile.status}
                  simName={spotlightTile.simName}
                  voiceOverCommand={spotlightTile.voiceOverCommand}
                  timeInHeadset={spotlightTile.timeInHeadset}
                  batteryPercentage={spotlightTile.batteryPercentage}
                  wifiSignal={spotlightTile.wifiSignal}
                  userStatus={spotlightTile.userStatus}
                  idleTime={spotlightTile.idleTime}
                  isAnonymous={spotlightTile.isAnonymous}
                  rating={spotlightTile.rating}
                  imgUrl={spotlightTile.imgUrl}
                  handleTileSelect={spotlightTile.handleTileSelect}
                  id={spotlightTile.id}
                  inactiveTime={spotlightTile.inactiveTime}
                  lastControllerAction={spotlightTile.lastControllerAction}
                  isSpotlightTile={spotlightTile.isSpotlightTile}
                  mqttStrength={spotlightTile.mqttStrength}
                />
              </div>
            )}
            <div
              className={clsx(
                "non-spotlight-tiles",
                spotlightTile && "overflow-tiles"
              )}
            >
              <MissionControlTiles
                classroom={classroom}
                users={users}
                setSpotlightTile={setSpotlightTile}
                spotlightTile={spotlightTile}
                setFullScreen={setFullScreen}
                missionControlDataArray={missionControlDataArray}
                loading={loading}
                showZero={showZero}
                isConnected={isConnected}
                showLiveFeed={showLiveFeed}
                onToggleLiveFeed={onToggleLiveFeed}
                fullScreen={fullScreen}
                imageData={imageData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
