import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import container from "../../../container";
import {
  Badge,
  Notification,
  NotificationType,
  HeadsetImage,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { useTimer } from "../../../lib/hooks";

import "./ih-activity-view.scss";

const IHActivityView = ({ title, courseId, description, timeToComplete }) => {
  const timer = useTimer();
  const timeTilExpire = 60 * 60;

  const { accountService } = container;

  const [showInstructions, setShowInstructions] = useState(true);
  const [generatingSimPass, setGeneratingSimPass] = useState();
  const [simPass, setSimPass] = useState();
  const { currentUser } = useStoreState((store) => store.account);
  const { userId } = currentUser;

  const generateClassroomLoginCode = async () => {
    setGeneratingSimPass(true);
    try {
      const payload = {
        userIds: [userId],
        classroomId: courseId,
      };

      const [result] = await accountService.createLoginCodes(payload);
      timer.setTimer(timeTilExpire);
      setSimPass(result?.code);
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setGeneratingSimPass(false);
  };

  useEffect(() => {
    if (timer.isDone) {
      setSimPass();
    }
  }, [timer.isDone]);

  return (
    <div className="ih-main-container">
      <div className="ih-content-container">
        <div className="ih-left-container">
          <Notification className={"estimated"} icon={["fa-solid", "fa-clock"]}>
            <div className="time-text">
              Estimated time to complete is&nbsp;
              <span className="bold">{timeToComplete ?? "?"} minutes</span>
            </div>
          </Notification>
          <div className="activity-title">{title}</div>
          <div className="activity-description">{description}</div>
          <div
            className="help-container"
            onClick={() => setShowInstructions(!showInstructions)}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-caret-right"
              rotation={showInstructions ? 90 : 0}
              className="chevron-icon"
            />
            <div className="help-text">How do I find this in my headset?</div>
          </div>
          {
            <div className={clsx("instructions", showInstructions && "show")}>
              <div className="instructions-container">
                <div className="instructions-badge">
                  <Badge className="badge" value={1} />
                </div>
                <div className="instructions-text-container">
                  <div className="text-title">Generate Sim Pass</div>
                  <div className="text-description">
                    Select the Get Sim Pass button on this screen to generate a
                    unique code. Write this code down or memorize it. Note which
                    application you need to use (such as Virtual Training
                    Facility).
                  </div>
                </div>
              </div>
              <div className="instructions-container">
                <div className="instructions-badge">
                  <Badge className="badge" value={2} />
                </div>
                <div className="instructions-text-container">
                  <div className="text-title">Grab VR headset</div>
                  <div className="text-description">
                    Start up VR headset. See{" "}
                    <span
                      onClick={() => console.log("navigate to startup guide")}
                    >
                      <span className="startup">startup guide</span>{" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-arrow-up-right-from-square"
                        rotation={0}
                        className="box-icon"
                      />{" "}
                    </span>
                    if you need further assistance.
                  </div>
                </div>
              </div>
              <div className="instructions-container">
                <div className="instructions-badge">
                  <Badge className="badge" value={3} />
                </div>
                <div className="instructions-text-container">
                  <div className="text-title">Select the application</div>
                  <div className="text-description">
                    Select the app where the sim is located, such as Virtual
                    Training Facility.
                  </div>
                </div>
              </div>
              <div className="instructions-container">
                <div className="instructions-badge">
                  <Badge className="badge" value={4} />
                </div>
                <div className="instructions-text-container">
                  <div className="text-title">
                    Enter Sim Pass in the headset
                  </div>
                  <div className="text-description">
                    Enter the Sim Pass code you generated on the login screen.
                    This should lead you directly to the menu you need, where
                    the simulation will be highlighted.
                  </div>
                </div>
              </div>
              <div className="instructions-container">
                <div className="instructions-badge">
                  <Badge className="badge" value={5} />
                </div>
                <div className="instructions-text-container">
                  <div className="text-title">Play the sim</div>
                  <div className="text-description">
                    Learn and practice new skills in the simulation. The results
                    will be recorded on this page automatically.
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="ih-right-container">
          <HeadsetImage />
          <div>
            {simPass ? (
              <Button
                disabled={generatingSimPass}
                className="sim-code-button"
                onClick={generateClassroomLoginCode}
              >
                {simPass}{" "}
                <span className="time">
                  <FontAwesomeIcon
                    icon="fa-solid fa-clock"
                    rotation={0}
                    className="box-icon"
                  />
                  {timer.timeFormatted}
                </span>
              </Button>
            ) : (
              <Button
                disabled={generatingSimPass}
                icon={"fa-regular fa-ticket"}
                className="sim-pass-button"
                onClick={generateClassroomLoginCode}
              >
                Get Sim Pass
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IHActivityView;
