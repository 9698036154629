import clsx from "clsx";
import React from "react";

import { DataBox, DataBoxGroup } from "@transfr-inc/dashboard-components";

import { StatusFilter } from "./trainee.classroom.ts.tab";

import "./trainee.databoxes.scss";

export function TraineeSimulationsFilter({
  masteryStats,
  simulations,
  onStatusSelected,
  className,
  isStudent,
}) {
  const {
    totalInProgress = 0,
    totalMastered = 0,
    totalWheelSpinning = 0,
  } = masteryStats || {};
  const totalStarted = totalInProgress + totalMastered + totalWheelSpinning;
  const allCount = simulations?.length;

  const dataBoxes = [
    <DataBox
      key={"trainee-simulations-filter-started"}
      label="Started"
      value={totalStarted ?? ""}
      description="Sims that have been mastered, need work, or where the trainee is stuck."
      onClick={() => onStatusSelected(StatusFilter.Started)}
    ></DataBox>,
    <DataBox
      key={"trainee-simulations-filter-mastered"}
      label="Mastered"
      value={totalMastered ?? ""}
      description="The trainee has completed the sim and received a score of 90% or higher."
      onClick={() => onStatusSelected(StatusFilter.Mastered)}
    ></DataBox>,
    <DataBox
      key={"trainee-simulations-filter-needs-work"}
      label="Needs work"
      value={totalInProgress ?? ""}
      description="The trainee has started the sim but hasn't achieved mastery yet."
      onClick={() => onStatusSelected(StatusFilter.NeedsWork)}
    ></DataBox>,
    <DataBox
      key={"trainee-simulations-filter-stuck"}
      label="Stuck"
      value={totalWheelSpinning ?? ""}
      description="The trainee has not made progress in improving their sim score."
      onClick={() => onStatusSelected(StatusFilter.Stuck)}
    ></DataBox>,
  ];

  const allBox = (
    <DataBox
      key={"trainee-simulations-filter-all"}
      label="View All"
      value={allCount ?? ""}
      description="All sims regardless of status."
      onClick={() => onStatusSelected(StatusFilter.All)}
    ></DataBox>
  );

  return (
    <DataBoxGroup className={clsx("sim-status-group", className)}>
      {isStudent ? [allBox, ...dataBoxes] : [...dataBoxes, allBox]}
    </DataBoxGroup>
  );
}
