import {
  newTracker,
  trackPageView,
  enableActivityTracking,
  trackSelfDescribingEvent,
  addGlobalContexts,
  setUserId,
} from "@snowplow/browser-tracker";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from "@snowplow/browser-plugin-link-click-tracking";
import {
  ButtonClickTrackingPlugin,
  enableButtonClickTracking,
} from "@snowplow/browser-plugin-button-click-tracking";
import {
  ErrorTrackingPlugin,
  enableErrorTracking,
} from "@snowplow/browser-plugin-error-tracking";

import { Impersonator, Organization } from "../models/tracking/entities";
import { EVENT_TYPES } from "../models/tracking/event-types";
import { DEFAULT_TRACKER_CONFIG } from "../config/tracking-config";

/**
 * TrackingService is a utility class that integrates Snowplow tracking into the application.
 * It manages tracker initialization, global contexts, and event tracking for custom use cases.
 */
class TrackingService {
  #organizationCode = "";
  #userId = "";
  #impersonatorId = "";
  #initialized = false;

  /**
   *
   * @param {string} appId - The application identifier to associate with the events being tracked.
   * @param {boolean} enabled - Indicates whether the tracker is enabled. If `false`, no events will be sent.
   */
  constructor(appId, enabled = false) {
    this.appId = appId;
    this.enabled = enabled;
  }

  /**
   * Sets the organization code, which is used to generate tracking contexts.
   * @param {string} organizationCode - The unique identifier for the organization.
   */
  setOrganizationCode(organizationCode) {
    this.#organizationCode = organizationCode;
  }

  /**
   * Sets the userId to be attached to all events.
   * @param {string} userId - The unique identifier for the current user.
   */
  setUserId(userId) {
    this.#userId = userId;
  }

  setImpersonatorId(impersonatorId) {
    this.#impersonatorId = impersonatorId;
  }

  /**
   * Checks if the Snowplow tracker has been properly initialized and is ready to track events.
   * @returns {boolean} True if tracker is initialized and ready, false otherwise
   */
  isInitialized() {
    return this.#initialized;
  }

  /**
   * Generates global contexts for events based on event type and current service state.
   * Excludes organization context for page views and link clicks.
   * Adds impersonator context when an impersonator ID is present.
   * @private
   * @param {object} args - Event arguments containing eventType
   * @returns {Array} Array of context entities for the event
   */
  #contextGenerator = (args) => {
    const excludedTypes = [EVENT_TYPES.PAGE_VIEW, EVENT_TYPES.LINK_CLICK];

    if (excludedTypes.includes(args.eventType)) {
      return [];
    }

    const contexts = [];

    if (this.#organizationCode) {
      contexts.push(new Organization(this.#organizationCode).toContext());
    }

    if (this.#impersonatorId) {
      contexts.push(new Impersonator(this.#impersonatorId).toContext());
    }

    return contexts;
  };

  /**
   * Handles failed requests to the Snowplow collector. This callback is triggered when a request fails.
   *
   * @private
   * @param {Object} failure - The failure information from Snowplow
   */
  #handleRequestFailure(failure) {
    if (!failure.willRetry) {
      console.error("Snowplow events lost after retry attempts:", {
        status: failure.status,
        message: failure.message,
        eventCount: failure.events.length,
        events: failure.events,
      });
    }
  }

  /**
   * Initializes the Snowplow tracker by configuring it with organization code and user ID.
   * This setup occurs before the tracker is fully initialized and begins tracking events.
   *
   * @param {string} trackerId - The unique identifier for the Snowplow tracker instance.
   * @param {string} collectorUrl - The endpoint URL of the Snowplow collector to receive event data.
   * @param {string} orgCode - A unique code for the organization, included with all tracked events.
   * @param {string} userId - A unique identifier for the user, attached to all events to track user actions.
   * @param {Object} [config=DEFAULT_TRACKER_CONFIG] - Optional configuration object to customize the tracker settings. Defaults to `DEFAULT_TRACKER_CONFIG` if not provided.
   */
  configure(
    trackerId,
    collectorUrl,
    currentUser,
    config = DEFAULT_TRACKER_CONFIG
  ) {
    if (!this.enabled) {
      console.info("Snowplow tracker not enabled");
      return;
    }

    this.setOrganizationCode(currentUser.organizationCode);
    this.setUserId(currentUser.userId);
    this.setImpersonatorId(currentUser.impersonatorId);

    this.initializeTracker(trackerId, collectorUrl, config);
  }

  /**
   * Initializes the Snowplow tracker with the given configuration and settings.
   * Sets up plugins, global contexts, activity tracking, and tracks an initial page view.
   * This method also configures cookies, performance timing, and tracking for user activity and button/link clicks.
   *
   * @param {string} trackerId - The unique identifier for the Snowplow tracker instance.
   * @param {string} collectorUrl - The endpoint URL of the Snowplow collector to send event data to.
   * @param {Object} [config] - Optional configuration object to customize tracker behavior.
   */
  initializeTracker(
    trackerId,
    collectorUrl,
    {
      cookieSameSite,
      performanceTiming,
      minimumVisitLength,
      heartbeatDelay,
    } = config
  ) {
    newTracker(trackerId, collectorUrl, {
      appId: this.appId,
      cookieSameSite,
      contexts: {
        performanceTiming,
      },
      plugins: [
        ButtonClickTrackingPlugin(),
        LinkClickTrackingPlugin(),
        ErrorTrackingPlugin(),
      ],
      onRequestFailure: this.#handleRequestFailure,
    });

    addGlobalContexts([this.#contextGenerator]);
    setUserId(this.#userId);

    enableErrorTracking();

    enableActivityTracking({
      minimumVisitLength,
      heartbeatDelay,
    });

    enableButtonClickTracking();
    enableLinkClickTracking({ pseudoClicks: true });

    trackPageView();
    this.#initialized = true;
  }

  /**
   * Tracks a custom event using a self-describing event schema.
   * Allows for flexible tracking of domain-specific or custom events.
   * @param {object} event - The event payload, which includes the schema and data.
   * @param {Array} [contexts=[]] - Optional additional contexts to attach to the event.
   */
  trackEvent(event, contexts = []) {
    if (!this.enabled || !this.#initialized) {
      console.warn(
        "Snowplow tracker not initialized or enabled, event skipped:",
        event
      );
      return;
    }

    trackSelfDescribingEvent({
      event,
      context: contexts,
    });
  }
}

export default TrackingService;
