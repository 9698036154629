import { useState } from "react";
import container from "../../container";
const { trackingService } = container;
function TrackingServiceWrapper({
  children,
  trackerId,
  collectorUrl,
  currentUser,
}) {
  const [initialized, setInitialized] = useState(
    trackingService.isInitialized()
  );

  if (!initialized) {
    trackingService.configure(trackerId, collectorUrl, currentUser);
    setInitialized(true);
  }

  return children;
}
export default TrackingServiceWrapper;
