import React, { useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
} from "@transfr-inc/dashboard-components";
import {
  getRequestsSetRef,
  RequestProgressEvent,
  RequestProgressStatus,
} from "../../../lib/http-client";

import "./request-progress.modal.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default () => {
  const [openModal, setOpenModal] = useState();
  const history = useHistory();
  const requestsSetRef = getRequestsSetRef();

  const timeoutListener = ({ detail }) => {
    const { url, status } = detail;
    const { current: requestsSet } = requestsSetRef;

    if (status === RequestProgressStatus.Start) {
      requestsSet.add(url);
      setOpenModal(true);
      console.error("ERROR: Request progress timeout! url:", url);
    } else {
      requestsSet.delete(url);
    }

    if (requestsSet.size === 0) {
      setOpenModal();
    }
  };

  useEffect(() => {
    document.addEventListener(RequestProgressEvent, timeoutListener);
    return () =>
      document.removeEventListener(RequestProgressEvent, timeoutListener);
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      requestsSetRef.current.clear();
      setOpenModal(false);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Modal
      open={openModal}
      modalClassName="request-progress-modal"
      onClose={() => setOpenModal()}
    >
      <ModalHeader
        title="We are still working on it..."
        icon="fa-regular fa-clock"
      ></ModalHeader>
      <ModalBody>
        We are still processing your request. Thanks for your patience.
      </ModalBody>
    </Modal>
  );
};
