import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import React, { cloneElement } from "react";

import {
  Tab,
  TabHeader,
  TabHeaders,
  Tabs,
} from "@transfr-inc/dashboard-components";

import { useHistoryState } from "../../../../lib/hooks";
import { Product, getProduct } from "../../../../models";
import { NoProductsNotification } from "../../components/no-products.notification";
import { CeTab } from "./ce.tab";
import { TrekTab } from "./trek.tab";
import { TsTab } from "./ts.tab";

import "./product.tabs.scss";

const hasProduct = (data, product) => !!data?.find((d) => d === product.id);

export const ProductTabs = ({ products }) => {
  const tsProduct = getProduct(Product.TS);
  const ceProduct = getProduct(Product.CE);
  const trekProduct = getProduct(Product.TRK);

  const { currentState, addStateProps } = useHistoryState();

  const setTabState = (tabName) => addStateProps({ selectedTab: tabName });

  const tsProductTab = {
    name: tsProduct.name,
    header: (
      <TabHeader
        name={tsProduct.name}
        className={`${tsProduct.name}-tab-header`}
      >
        {tsProduct.name}
      </TabHeader>
    ),
    content: (
      <Tab name={tsProduct.name}>
        <TsTab></TsTab>
      </Tab>
    ),
  };

  const ceProductTab = {
    name: ceProduct.name,
    header: (
      <TabHeader
        name={ceProduct.name}
        className={`${ceProduct.name}-tab-header`}
      >
        Trek<span>(VR)</span>
      </TabHeader>
    ),
    content: (
      <Tab name={ceProduct.name}>
        <CeTab></CeTab>
      </Tab>
    ),
  };

  const trekProductTab = {
    name: trekProduct.name,
    header: (
      <TabHeader
        name={trekProduct.name}
        className={`${trekProduct.name}-tab-header`}
      >
        Trek<span>(Mobile)</span>
      </TabHeader>
    ),
    content: (
      <Tab name={trekProduct.name}>
        <TrekTab />
      </Tab>
    ),
  };

  const getProductTabs = () => {
    const tabs = [];
    hasProduct(products, tsProduct) && tabs.push(tsProductTab);
    hasProduct(products, ceProduct) && tabs.push(ceProductTab);
    hasProduct(products, trekProduct) && tabs.push(trekProductTab);

    return tabs;
  };

  const tabs = getProductTabs();

  return products ? (
    <Tabs
      selectedTabName={currentState?.selectedTab ?? tabs[0]?.name}
      className={"products-tabs"}
    >
      <TabHeaders
        className={clsx(tabs?.length > 1 && "tab-header-border")}
        onSelectedTabChange={setTabState}
      >
        {tabs?.length > 1 &&
          tabs.map(({ header, name }, i) =>
            cloneElement(header, { key: `tab-header-${name}-${i}` })
          )}
      </TabHeaders>
      {tabs.map(({ content, name }, i) =>
        cloneElement(content, { key: `tab-content-${name}-${i}` })
      )}
    </Tabs>
  ) : (
    <NoProductsNotification />
  );
};
