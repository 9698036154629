import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TextTooltip,
  OverflowText,
  NotificationStack,
} from "@transfr-inc/dashboard-components";
import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";

import {
  Column,
  ColumnType,
  SortDirection,
  ColumnOptions,
  DataTable,
  BasicCell,
  PercentageDataCell,
  TimeDataCell,
} from "@transfr-inc/dashboard-components/data-table";

import { MasteryStatus } from "../../../../models/mastery";

import { formatDateDigits } from "@transfr-inc/dashboard-components/utils";

import "./trainee.sims-table.scss";
import { getSimulationStatus } from "../../../../models/simulation-status";
import { TraineeShortcutCode } from "./trainee.shortcut-code";
import { ColumnHeaderInformationTooltip } from "../../../../components/custom/content/column-header-information-tooltip";

export function TraineeSimsTable({
  sims,
  responsiveStyles,
  isStudent,
  classroom,
  user,
  codeGeneratedMapping,
  onCodeGenerated,
}) {
  const [tableData, setTableData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const { isDesktop } = useScreenBreakpoint();

  const onCloseNotification = (indexToRemove) => {
    setNotifications(
      notifications.filter((n, index) => index !== indexToRemove)
    );
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  useEffect(() => {
    const tableData = sims.map((user) => {
      let completedCompletionDate = { completed: false, simCompletionDate: "" };
      if (user.completed) {
        completedCompletionDate.completed = true;
        if (user.simCompletionDate) {
          completedCompletionDate.simCompletionDate = user.simCompletionDate;
        }
      }
      return { ...user, completedCompletionDate };
    });

    setTableData(tableData);
  }, [sims]);

  const columns = [
    new Column(
      "displayName",
      "Sim",
      ColumnType.BASIC,
      new ColumnOptions({
        sortingEnabled: false,
        overflowEnabled: true,
      })
    ),
    ...(isStudent
      ? [
          new Column(
            "appDescription",
            "App",
            ColumnType.CUSTOM,
            new ColumnOptions({
              sortingEnabled: false,
              customFunction: (value, _row) => {
                return (
                  <OverflowText text={value || "N/A"}>
                    {value || "N/A"}
                  </OverflowText>
                );
              },
            })
          ),
          new Column(
            "categories",
            "Category",
            ColumnType.CUSTOM,
            new ColumnOptions({
              sortingEnabled: false,
              customFunction: (value, row) => {
                return (
                  <OverflowText
                    text={value.map((item) => item.categoryName).join(", ")}
                  >
                    {value.map((item) => item.categoryName).join(", ")}
                  </OverflowText>
                );
              },
            })
          ),
        ]
      : []),
    new Column(
      "moduleName",
      "Module",
      ColumnType.BASIC,
      new ColumnOptions({ sortingEnabled: isDesktop, overflowEnabled: true })
    ),
    ...(isStudent
      ? []
      : [
          new Column(
            "timeSpent",
            "Time",
            ColumnType.CUSTOM,
            new ColumnOptions({
              sortingEnabled: isDesktop,
              customFunction: TimeDataCell,
            })
          ),
        ]),
    new Column(
      "status",
      "Status",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: isDesktop,
        customFunction: (value) => {
          const status =
            value === MasteryStatus.Stuck ? (
              <span className="stuck-badge">{value}</span>
            ) : (
              value
            );

          const description = value && getSimulationStatus(value)?.description;
          return (
            <div className="status-cell">
              {status}
              <div className="status-tooltip">
                <TextTooltip text={description} position={"top-end"}>
                  <FontAwesomeIcon icon={"fa-regular fa-circle-info"} />
                </TextTooltip>
              </div>
            </div>
          );
        },
      })
    ),
    new Column(
      "mastery",
      "Score",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: isDesktop,
        customFunction: (value, row) => {
          const alwaysShowScore =
            row.status !== MasteryStatus.NotStarted && row.status;
          return PercentageDataCell(value, "truncate", alwaysShowScore);
        },
      })
    ),
    ...(isStudent
      ? []
      : [
          new Column(
            "attempts",
            "Attempts",
            ColumnType.COUNT,
            new ColumnOptions({ sortingEnabled: isDesktop })
          ),
        ]),
    ...(isStudent
      ? []
      : [
          new Column(
            "latestTimeStamp",
            "Last Attempted",
            ColumnType.CUSTOM,
            new ColumnOptions({
              sortingEnabled: isDesktop,
              customFunction: (value) => {
                const formattedDate = value
                  ? formatDateDigits(value)
                  : undefined;
                return <BasicCell value={formattedDate}></BasicCell>;
              },
            })
          ),
        ]),
    new Column(
      "completedCompletionDate",
      "Complete",
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: isDesktop,
        customFunction: (value) => {
          const formattedDate = value.simCompletionDate
            ? formatDateDigits(value.simCompletionDate)
            : undefined;
          return (
            <div className="completed-cell">
              {value.completed ? (
                <>
                  <FontAwesomeIcon icon="fa-solid fa-circle-check"></FontAwesomeIcon>
                  {formattedDate}
                </>
              ) : (
                <BasicCell />
              )}
            </div>
          );
        },
      })
    ),
    ...(isStudent
      ? [
          new Column(
            "shortcutCode",
            (
              <ColumnHeaderInformationTooltip
                headerTitle={"Sim Pass"}
                tooltipText={
                  "Press the plus to generate a sim pass. Codes can be entered in your headset to skip the main navigation and go directly to this simulation."
                }
              ></ColumnHeaderInformationTooltip>
            ),
            ColumnType.CUSTOM,
            new ColumnOptions({
              sortingEnabled: false,
              customFunction: (value, row) => {
                return (
                  <TraineeShortcutCode
                    categories={row.categories}
                    loId={row.learningObjectiveId}
                    leId={row.learningExperienceId}
                    onShowNotification={showNotification}
                    simName={row.displayName}
                    classroom={classroom}
                    user={user}
                    onCodeGenerated={onCodeGenerated}
                    codesGenerated={codeGeneratedMapping}
                  ></TraineeShortcutCode>
                );
              },
            })
          ),
        ]
      : []),
  ];

  return (
    <>
      <DataTable
        className={clsx(
          "trainee-sims-table",
          isStudent && "student-experience",
          responsiveStyles
        )}
        columns={columns}
        defaultSortDirection={SortDirection.DESCENDING}
        defaultSortPropName={"latestTimeStamp"}
        data={tableData}
        keyFields={["learningObjectiveId"]}
        responsiveStyles={responsiveStyles}
        rowHeightConfig={{ large: 54, medium: 350, small: 350 }}
        virtualRowEnabled
        minViewportHeight={650}
      ></DataTable>
      <div className="notification-container">
        <NotificationStack
          notifications={notifications}
          onClose={onCloseNotification}
          closable
        />
      </div>
    </>
  );
}
