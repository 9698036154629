import { action, computed, thunk } from "easy-peasy";
import { Product, Role } from "../models";
import {
  getClassrooms,
  getOrganizationUsers,
  getConnectionsList,
} from "../services";

export default ({ organizationService, toggles }) => {
  return {
    organizations: [],
    classrooms: [],
    users: undefined,
    loadingOrganizations: false,
    organizationsLoaded: false,
    loadingClassrooms: false,
    classroomsLoaded: false,
    usersLoaded: false,
    integrationsLoaded: false,

    totalClassrooms: computed((state) => {
      return state.organizations.length > 0
        ? // eslint-disable-next-line unicorn/no-array-reduce
          state.organizations.reduce((p, c) => p + c.totalClassrooms, 0)
        : undefined;
    }),

    totalInstructors: computed((state) => {
      return state.organizations.length > 0
        ? // eslint-disable-next-line unicorn/no-array-reduce
          state.organizations.reduce((p, c) => p + c.totalInstructors, 0)
        : undefined;
    }),

    totalTrainees: computed((state) => {
      return state.organizations.length > 0
        ? // eslint-disable-next-line unicorn/no-array-reduce
          state.organizations.reduce((p, c) => p + c.totalTrainees, 0)
        : undefined;
    }),

    students: computed((state) =>
      state.users?.filter((u) => u.roleId === Role.STUDENT.id)
    ),

    getOrganizationByCode: computed((state) => (orgCode) => {
      const org = state.organizations.find((o) => o.code === orgCode);
      return org;
    }),

    setOrganizations: action((state, orgs) => {
      state.organizations = orgs;
    }),

    setLoadingOrganizations: action((state, value) => {
      state.loadingOrganizations = value;
    }),

    setUsers: action((state, value) => {
      state.users = value;
    }),

    setClassrooms: action((state, value) => {
      state.classrooms = value;
    }),

    setLoadingClassrooms: action((state, value) => {
      state.loadingClassrooms = value;
    }),

    setClassroomsLoaded: action((state, value) => {
      state.classroomsLoaded = value;
    }),

    setOrganizationsLoaded: action((state, value) => {
      state.organizationsLoaded = value;
    }),

    setUsersLoaded: action((state, value) => {
      state.usersLoaded = value;
    }),

    setIntegrationsLoaded: action((state, value) => {
      state.integrationsLoaded = value;
    }),

    setIntegrations: action((state, value) => {
      state.integrationsList = value;
    }),

    clearStore: action((state) => {
      state.users = undefined;
      state.organizations = [];
      state.classrooms = [];
      state.organizationsLoaded = false;
      state.usersLoaded = false;
      state.classroomsLoaded = false;
      state.integrationsLoaded = false;
      state.integrationsList = [];
    }),

    getUsers: thunk(async (actions, payload, { getStoreState }) => {
      const { organization } = getStoreState();
      const { users } = organization;
      return users ? users : actions.refreshOrganizationUsers(payload);
    }),

    getOrganizations: thunk(async (actions, orgCode) => {
      try {
        actions.setOrganizationsLoaded(false);
        actions.setLoadingOrganizations(true);
        actions.setOrganizations([]);
        const orgs = await organizationService.getChildOrganizationStats(
          orgCode
        );
        actions.setOrganizations(orgs);
        actions.setOrganizationsLoaded(true);
        return orgs;
      } catch (error) {
        console.error(`[OrganizationService] - There was an error: ${error}`);
        actions.setOrganizations([]);
      } finally {
        actions.setLoadingOrganizations();
      }
    }),

    getClassrooms: thunk(async (actions, { userId, role, orgCode }) => {
      try {
        actions.setClassroomsLoaded(false);
        actions.setLoadingClassrooms(true);
        actions.setClassrooms([]);
        const response = await getClassrooms(userId, role, orgCode);
        actions.setClassrooms(response);
        actions.setClassroomsLoaded(true);
        return response;
      } catch (error) {
        console.error(`[ClassroomService] - There was an error: ${error}`);
        actions.setClassrooms([]);
      } finally {
        actions.setLoadingClassrooms();
      }
    }),

    refreshOrganizationUsers: thunk(
      async (actions, { orgCode, role, userId }) => {
        try {
          actions.setUsersLoaded(false);
          const data = await getOrganizationUsers(role, orgCode, userId);
          actions.setUsers(data);
          actions.setUsersLoaded(true);
          return data;
        } catch (error) {
          console.error(`[OrganizationService] - There was an error: ${error}`);
        }
      }
    ),

    getIntegrations: thunk(async (actions, { orgCode }) => {
      try {
        actions.setIntegrationsLoaded(false);
        const data = await getConnectionsList(orgCode);
        actions.setIntegrations(data);
        actions.setIntegrationsLoaded(true);
        return data;
      } catch (error) {
        console.error(`[OrganizationService] - There was an error: ${error}`);
      }
    }),
  };
};
