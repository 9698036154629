import { setDailyStats } from "../lib/chart-utils";
import HttpClient from "../lib/http-client";

const defaultExportOptions = {
  createFile: true,
};

/**
 * @memberof Services
 */
export class ClassroomService extends HttpClient {
  getClassroomsStatsForOrg(orgCode) {
    const apiUrl = `/organization/${orgCode}/classroom/stats`;
    return this.cachedRequest(apiUrl);
  }

  async getClassroomsForSingleOrg(orgCode) {
    const apiUrl = `/classroom/organization/${orgCode}/classrooms`;

    const data = await this.http.get(apiUrl);
    return data;
  }

  async deleteClassrooms({ classroomIds }) {
    const apiUrl = `/classroom/bulk/deactivation`;
    return this.http.put(apiUrl, { classroomIds });
  }

  async getClassroomsForInstructor(instructorId) {
    const apiUrl = `/classroom/instructor/${instructorId}/classrooms`;

    const data = await this.http.get(apiUrl);
    return data;
  }

  getClassroomsStatsForInstructor(instructorId) {
    const apiUrl = `/user/instructor/${instructorId}/classroom/stats`;
    return this.cachedRequest(apiUrl);
  }

  getMultiOrgClassroomsStats(orgCode) {
    const apiUrl = `/multi-organization/${orgCode}/classroom/stats`;
    return this.cachedRequest(apiUrl);
  }

  async getClassroomDetails(classroomId) {
    const apiUrl = `/classroom/${classroomId}`;
    return this.http.get(apiUrl);
  }

  async getClassroomInstructors(classroomId) {
    const apiUrl = `/classroom/${classroomId}/instructors`;
    return this.http.get(apiUrl);
  }

  async getClassroomTrainees(classroomId) {
    const apiUrl = `/classroom/${classroomId}/trainees`;
    return this.http.get(apiUrl);
  }

  async updateClassroomName(classroomId, name) {
    const apiUrl = `/classroom/${classroomId}/name`;
    return this.http.put(apiUrl, { name });
  }

  async updateClassroomIsStreamingEnabled(classroomId, isStreamingEnabled) {
    const apiUrl = `/classroom/${classroomId}/is-streaming-enabled`;
    return this.http.put(apiUrl, { isStreamingEnabled });
  }

  async removeTrainee(classroomId, userId) {
    const apiUrl = `/classroom/${classroomId}/trainee/${userId}`;
    return this.http.delete(apiUrl);
  }

  async bulkRemoveTrainee(classroomId, userIds) {
    const apiUrl = `/classroom/${classroomId}/remove/trainees`;
    return this.http.put(apiUrl, userIds);
  }

  async saveClassroom(classroom) {
    const apiUrl = "/classroom/";
    return this.http.post(apiUrl, classroom);
  }

  getClassroomStats(classroomId, filters) {
    const apiUrl = `/classroom/${classroomId}/stats/`;
    return this.http
      .post(apiUrl, { filters })
      .then((response) => response?.pop());
  }

  getClassroomDailyStats(classroomId, filters) {
    const apiUrl = `/classroom/${classroomId}/daily/stats/`;
    return this.http
      .post(apiUrl, { filters })
      .then((response) => setDailyStats(filters, response));
  }

  getCeClassroomStats(classroomId, filters) {
    const apiUrl = `/classroom/${classroomId}/ce/stats`;
    return this.http.post(apiUrl, { filters });
  }

  getCeClassroomDailyStats(classroomId, filters) {
    const apiUrl = `/classroom/${classroomId}/daily/ce/stats/`;
    return this.http
      .post(apiUrl, { filters })
      .then((response) => setDailyStats(filters, response));
  }

  getClassroomTopCareers(classroomId, filters) {
    const apiUrl = `/classroom/${classroomId}/ce/top-careers`;
    return this.http.post(apiUrl, { filters });
  }

  getClassroomCareerRatings(classroomId, learningExperienceId, filters) {
    const apiUrl = `/classroom/${classroomId}/ce/career/${learningExperienceId}/ratings`;
    return this.http.post(apiUrl, { filters });
  }

  exportClassroomStats(classroomId, filters, fileName) {
    const requestConfig = {
      method: "post",
      url: `/classroom/${classroomId}/stats/export`,
      data: { filters },
    };
    return this.downloadBlob(requestConfig, fileName);
  }

  exportClassroomCeStats(classroomId, filters, fileName, options) {
    const requestConfig = {
      method: "post",
      url: `/classroom/${classroomId}/ce/stats/export`,
      data: { filters },
    };

    const exportOptions = { ...defaultExportOptions, ...options };

    return exportOptions.createFile
      ? this.downloadBlob(requestConfig, fileName)
      : this.parseCsvResponse(requestConfig);
  }

  removeInstructor(classroomId, instructorId) {
    const apiUrl = `/classroom/${classroomId}/instructor/${instructorId}`;
    return this.http.delete(apiUrl);
  }

  addTrainee(classroomId, traineeId) {
    const apiUrl = `/classroom/${classroomId}/trainee/${traineeId}`;
    return this.http.post(apiUrl);
  }

  addInstructor(classroomId, instructorId) {
    const apiUrl = `/classroom/${classroomId}/instructor/${instructorId}`;
    return this.http.post(apiUrl);
  }

  async getQuickSignupQRCode(classroomId) {
    const apiUrl = `/classroom/${classroomId}/qr-code`;
    return this.http.get(apiUrl);
  }

  async getSimPassPDF(classroomId, productId) {
    const apiUrl = `classroom/${classroomId}/sim-pass-pdf${
      productId ? `?product_id=${productId}` : ""
    }`;

    return await this.http.get(apiUrl, {
      responseType: "blob",
    });
  }
}
