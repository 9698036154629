import React, { useEffect } from "react";

import { DataBoxGroup } from "@transfr-inc/dashboard-components";

const ChartFilter = {
  ActiveExplorers: {
    key: "totalActiveUsers",
    label: "Unique Explorers",
  },
  ExploredVR: {
    key: "totalVRSimsExplored",
    label: "Explored VR",
  },
  CareersExplored: {
    key: "totalCareersExplored",
    label: "Careers Explored",
  },
  CareersSelected: {
    key: "totalPlansSubmitted",
    label: "Careers Selected",
  },
};

export function TrekDataboxes({ data, onCategoryChange }) {
  const dataBoxConfig = [
    {
      label: ChartFilter.ActiveExplorers.label,
      key: ChartFilter.ActiveExplorers.key,
      alt: "Icon for a user avatar wearing a hardhat.",
      description:
        "Explorers who have logged into Trek and completed the Interest Inventory",
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.ActiveExplorers),
    },
    {
      label: ChartFilter.ExploredVR.label,
      key: ChartFilter.ExploredVR.key,
      alt: "Icon for a user wearing a VR headset.",
      description: "Number of careers explorers have completed in VR.",
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.ExploredVR),
    },
    {
      label: ChartFilter.CareersExplored.label,
      key: ChartFilter.CareersExplored.key,
      alt: "Icon for a checkmark within a circle.",
      description: "Number of careers explorers have viewed",
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.CareersExplored),
    },
    {
      label: ChartFilter.CareersSelected.label,
      key: ChartFilter.CareersSelected.key,
      alt: "Icon for a medal.",
      description: "Number of submitted career plans",
      className: "data-box",
      onClick: () => onCategoryChange(ChartFilter.CareersSelected),
    },
  ];

  useEffect(() => {
    dataBoxConfig[0].onClick();
  }, []);

  return (
    <DataBoxGroup data={data} dataBoxConfig={dataBoxConfig} className="rows" />
  );
}
