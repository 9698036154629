import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { TraineeSimulationsFilter } from "./trainee.ts.databoxes";
import { RemoveTraineeModal } from "../modals/remove-trainee";
import { InstructorEmailBox } from "./instructor.email-box";

import {
  formatTime,
  formatTimestap,
} from "@transfr-inc/dashboard-components/utils";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";
import { ClassroomRoute, RootPath } from "../../../../lib/routes";
import { useApiRequest } from "../../../../lib/http-client";

import container from "../../../../container";

import "./trainee.insights.scss";
import { StatusDropdownList } from "../../../../components/custom/dropdowns";
import { Role } from "../../../../models";
import { classroomUrlMap } from "../../../../lib/classroom-url-map";
import { SimPassButton } from "../../../../components/sim-pass-button";

export function TraineeInsights({
  trainee,
  classroom,
  simulations,
  onRemoveTrainee,
  removeTraineeEnabled,
  onStatusSelected,
  selectedStatus,
  role,
}) {
  const [masteryStats, setMasteryStats] = useState();
  const [openRemoveModal, setOpenRemoveModal] = useState();
  const [instructors, setInstructors] = useState();
  const [deletingSimPass, setDeletingSimPass] = useState(false);
  const [generatingSimPass, setGeneratingSimPass] = useState(false);
  const [simPass, setSimPass] = useState();

  const { accountService, insightsService, classroomService } = container;
  const { url } = useRouteMatch(`${RootPath}`);
  const history = useHistory();
  const { isDesktop } = useScreenBreakpoint();

  const { loading: loadingFile, sendRequest: exportTraineeData } =
    useApiRequest(() => {
      const { organizationCode, userId, firstName, lastName } = trainee;
      const fileName = `${firstName}_${lastName}-${formatTimestap()}`;

      return insightsService.exportTraineeData(
        organizationCode,
        userId,
        fileName
      );
    }, false);

  useUnloadWarning({ trigger: loadingFile });

  useEffect(() => {
    if (role !== Role.STUDENT.id) {
      if (classroom) {
        getUserMasteryStats();
        getInstructors();
      }
    } else {
      simulations?.length &&
        getUserMasteryStats(simulations.map((obj) => obj.learningObjectiveId));
      classroom && getInstructors();
    }
    return () => insightsService.cancelRequest();
  }, [simulations]);

  const getUserMasteryStats = (loIds) => {
    insightsService
      .getUserMasteryStats(trainee.userId, loIds)
      .then(setMasteryStats)
      .catch(() => setMasteryStats({}));
  };

  const getInstructors = () => {
    classroomService
      .getClassroomInstructors(classroom.classroomId)
      .then(setInstructors)
      .catch(() => setInstructors({}));
  };

  const goClassroomDetailsPage = () => {
    const location = {
      pathname: `${url}${ClassroomRoute.path}/${classroomUrlMap(
        classroom.products
      )}/${classroom.code}`,
      state: {
        classroomId: classroom.classroomId,
        breadcrumbLabel: `Trainee`,
      },
    };
    history.push(location);
  };

  const onRemoveTraineeHandler = (trainee, classroom) => {
    return onRemoveTrainee(trainee, classroom).then(() => setOpenRemoveModal());
  };

  async function deleteLoginCode(simulationId) {
    setDeletingSimPass(true);
    try {
      const payload = {
        userId: trainee.userId,
        classroomId: classroom.classroomId,
      };
      await accountService.deleteCodes(payload);
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setDeletingSimPass(false);
  }

  const generateClassroomLoginCode = async () => {
    setGeneratingSimPass(true);
    try {
      const payload = {
        userIds: [trainee.userId],
        classroomId: classroom.classroomId,
      };

      const [result] = await accountService.createLoginCodes(payload);
      setSimPass(result);
    } catch {
      addNotification({
        type: NotificationType.error,
        message: "Something went wrong. Please try again.",
      });
    }
    setGeneratingSimPass(false);
  };

  async function deleteClassroomSimPass() {
    await deleteLoginCode();
    setSimPass();
  }

  return (
    <>
      <div className="insights-section">
        <div className="top-section">
          <div className="insights-header">
            <div className="title-section">
              <h2 className="title">
                {instructors?.length > 1 ? "Instructors" : "Instructor"}{" "}
                <span className="badge">{instructors?.length}</span>
              </h2>
            </div>
            <div className="list">
              {instructors?.length > 0 ? (
                instructors.map((instructor) => (
                  <InstructorEmailBox
                    instructor={instructor}
                    key={instructor.userId}
                  ></InstructorEmailBox>
                ))
              ) : (
                <div />
              )}
            </div>
          </div>

          {role === Role.STUDENT.id && (
            <SimPassButton
              classroom={classroom}
              deletingSimPass={deletingSimPass}
              generatingSimPass={generatingSimPass}
              simPass={simPass}
              onClick={generateClassroomLoginCode}
              onDelete={deleteClassroomSimPass}
            />
          )}
        </div>

        <div className="insights-header">
          <div className="title-section">
            <h2 className="title">
              {"Simulations"}{" "}
              <span className="badge">{simulations?.length}</span>
            </h2>
            {role !== Role.STUDENT.id && (
              <div className="time-spent-section">
                <h3>Time in headset</h3>
                <span>
                  {masteryStats?.totalTimespent ? (
                    formatTime(masteryStats?.totalTimespent)
                  ) : (
                    <FontAwesomeIcon icon={"fa-solid fa-hyphen"} />
                  )}
                </span>
              </div>
            )}
            {!isDesktop && (
              <StatusDropdownList
                className="insights-simulations-filter"
                masteryStats={masteryStats}
                simulations={simulations}
                onStatusSelected={onStatusSelected}
                selectedStatus={selectedStatus}
                isStudent={role == Role.STUDENT.id}
              ></StatusDropdownList>
            )}
          </div>
          {role !== Role.STUDENT.id && (
            <div className="actions-section">
              {removeTraineeEnabled && (
                <Button
                  size="small"
                  icon={["fa-light", "user-xmark"]}
                  onClick={() => setOpenRemoveModal(true)}
                >
                  Remove Trainee
                </Button>
              )}
              <Button
                size="small"
                icon={["fa-light", "file-export"]}
                disabled={loadingFile}
                onClick={exportTraineeData}
              >
                Export Data (.csv)
              </Button>
              <Button
                size="small"
                primary
                icon={["fa-light", "screen-users"]}
                onClick={goClassroomDetailsPage}
              >
                View Classroom
              </Button>
            </div>
          )}
        </div>
        {isDesktop && (
          <TraineeSimulationsFilter
            className="insights-databoxes"
            masteryStats={masteryStats}
            simulations={simulations}
            onStatusSelected={onStatusSelected}
            isStudent={role == Role.STUDENT.id}
          ></TraineeSimulationsFilter>
        )}
        <div className="notification-loader-container">
          {loadingFile && (
            <Notification
              type={NotificationType.info}
              closable
              icon={["fa-solid", "spinner"]}
              iconConfig={{ spin: true }}
            >
              We’re preparing a download of{" "}
              <strong>{`${trainee.firstName} ${trainee.lastName}'s`}</strong>{" "}
              progress in <strong>{classroom.name}</strong>. Please wait...
            </Notification>
          )}
        </div>
      </div>
      {removeTraineeEnabled && (
        <RemoveTraineeModal
          open={openRemoveModal}
          onClose={() => setOpenRemoveModal(false)}
          onRemoveTrainee={onRemoveTraineeHandler}
          trainee={trainee}
          classroom={classroom}
        ></RemoveTraineeModal>
      )}
    </>
  );
}
