import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";

import "./shortcuts.header.scss";

export default ({ icon, className }) => {
  return (
    <div className={clsx("shortcuts-header", className)}>
      <div className="badge">
        <FontAwesomeIcon icon={icon} inverse />
      </div>
    </div>
  );
};
