/**
 * Base class for all Snowplow analytics events.
 * Provides a standardized structure for event tracking with a schema
 * and data payload. All specific event types should extend this class.
 *
 * @abstract
 */
class Event {
  /**
   * Creates a new event with the specified Snowplow schema.
   * @param {string} schema - The Iglu schema URI that defines the event structure
   */
  constructor(schema) {
    this.schema = schema;
    this.data = {};
  }
}

/**
 * Tracks user interactions with dashboard date filtering.
 */
export class FilterDashboard extends Event {
  /**
   * Creates a new filter dashboard event with specified date range.
   * @param {string} startDate - The start date of the filter.
   * @param {string} endDate - The end date of the filter.
   */
  constructor(startDate, endDate) {
    super("iglu:com.transfrvr/filter_dashboard/jsonschema/1-0-0");
    this.data.start_date = startDate;
    this.data.end_date = endDate;
  }
}

/**
 * Tracks user logout events with the deauthentication method and if user was impersonator.
 */
export class DeauthenticateUser extends Event {
  /**
   * Creates a new deauthentication method event with the specified deauthentication method
   * which can either be "core-platform", "impersonation", or "SSO"
   * @param {string} deauthenticationMethod - Specifies the method used for deauthentication.
   */
  constructor(deauthenticationMethod) {
    super("iglu:com.transfrvr/deauthenticate_user/jsonschema/3-0-0");
    this.data = {
      deauthentication_method: deauthenticationMethod,
    };
  }
}

/**
 * Tracks whenever the user navigates to on the Organization tab on the dashboard.
 */
export class ViewOrganizationList extends Event {
  /**
   * Creates a new ViewOrganizationList event.
   */
  constructor() {
    super("iglu:com.transfrvr/view_organization_list/jsonschema/1-0-0");
  }
}

/**
 * Tracks whenever the user navigates to on the Classrooms tab on the dashboard.
 */
export class ViewClassroomList extends Event {
  /**
   * Creates an instance of a ViewClassroomList event.
   */
  constructor() {
    super("iglu:com.transfrvr/view_classroom_list/jsonschema/1-0-0");
  }
}

/**
 * Tracks whenever the user navigates to the Users tab on the dashboard.
 */
export class ViewUserList extends Event {
  /**
   * Creates an isntance of a ViewUserList event.
   * @param {string} viewName e - The name of the selected tab the user is viewing.
   * @param {string} viewedAppId - The ID of the product the user is interacting with (optional).
   */
  constructor(viewName, viewedAppId = "") {
    super("iglu:com.transfrvr/view_user_list/jsonschema/1-0-0");
    this.data = {
      view_name: viewName,
      viewed_app_id: viewedAppId,
    };
  }
}

/**
 * Tracks whenever the user navigates to the Catalog tab on the dashboard.
 */
export class ViewCatalog extends Event {
  /**
   * Creates an instance of a ViewCatalog event.
   */
  constructor(viewedAppId = "") {
    super("iglu:com.transfrvr/view_catalog/jsonschema/1-0-0");
    this.data.viewed_app_id = viewedAppId;
  }
}

/**
 * Tracks whenever the user navigates to the Support tab on the dashboard.
 */
export class ViewSupport extends Event {
  /**
   * Creates an instance of a ViewSupport event.
   */
  constructor(viewedAppId = "") {
    super("iglu:com.transfrvr/view_support/jsonschema/1-0-0");
    this.data.viewed_app_id = viewedAppId;
  }
}

/**
 * Tracks whenever the user navigates to a specific classroom and changes active tab.
 */
export class ViewClassroom extends Event {
  /**
   * Creates an instance of a ViewClassroom event.
   */
  constructor(viewName, viewedAppId, viewedClassroomId) {
    super("iglu:com.transfrvr/view_classroom/jsonschema/2-0-0");
    this.data = {
      view_name: viewName,
      viewed_app_id: viewedAppId,
      viewed_classroom_id: viewedClassroomId,
    };
  }
}

/**
 * Tracks whenever the user navigates to a Trainee Details page and switches active classroom tab.
 */
export class ViewUser extends Event {
  /**
   * Creates an instance of a ViewUser event.
   */
  constructor(viewName, viewedAppId, viewedClassroomId, viewedUserId) {
    super("iglu:com.transfrvr/view_user/jsonschema/1-0-0");
    this.data = {
      view_name: viewName,
      viewed_app_id: viewedAppId,
      viewed_classroom_id: viewedClassroomId,
      viewed_user_id: viewedUserId,
    };
  }
}

/**
 * Tracks whenever the user navigates to the profile page on the dashboard.
 */
export class ViewProfile extends Event {
  /**
   * Creates an instance of a ViewProfile event.
   */
  constructor(viewedAppId = "") {
    super("iglu:com.transfrvr/view_profile/jsonschema/1-0-0");
    this.data.viewed_app_id = viewedAppId;
  }
}
