import { Page } from "../models/tracking/entities";
import { DeauthenticateUser, FilterDashboard } from "../models/tracking/events";
import container from "../container";

const { trackingService } = container;

export const trackLogout = (logoutType) => {
  if (!logoutType) return;

  trackingService.trackEvent(new DeauthenticateUser(logoutType));
};

export const trackView = (viewClass, pageName, ...args) => {
  const event = new viewClass(...args);
  trackingService.trackEvent(event, [new Page(pageName)]);
};

export const trackFilterEvent = (startDate, endDate) => {
  trackingService.trackEvent(new FilterDashboard(startDate, endDate));
};
