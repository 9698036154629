import React from "react";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionHeaderName,
  TextTooltip,
} from "@transfr-inc/dashboard-components";
import LessonsMasteriesTable from "./lessons-masteries.table";
import "./unit-lesson-accordion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function UnitLessonAccordion({
  lesson,
  isExpanded,
  index,
  hideExpandProperties = false,
  responsiveStyles,
  courseId,
  courseName,
  unitName,
  checkCompleted,
}) {
  const allCompleted = lesson?.masteries?.every((mastery) => mastery.completed);

  return (
    <Accordion
      className="unit-lesson-accordion"
      isOpen={isExpanded}
      hideExpandProperties={hideExpandProperties}
    >
      <div className="unit-lesson-accordion-header">
        <AccordionHeader>
          <AccordionHeaderName>
            <div className="unit-lesson-accordion-header-name">
              <div>
                Lesson {index}: {lesson?.title}
              </div>
              <div className="completed-cell">
                {allCompleted ? (
                  <>
                    <FontAwesomeIcon icon="fa-solid fa-circle-check"></FontAwesomeIcon>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon="fa-regular fa-circle"></FontAwesomeIcon>
                  </>
                )}
              </div>
            </div>
          </AccordionHeaderName>
        </AccordionHeader>
      </div>
      <AccordionBody>
        <LessonsMasteriesTable
          lesson={lesson}
          responsiveStyles={responsiveStyles}
          courseId={courseId}
          courseName={courseName}
          unitName={unitName}
          checkCompleted={checkCompleted}
        />
      </AccordionBody>
    </Accordion>
  );
}
