import React, { useState } from "react";
import { Button } from "@transfr-inc/dashboard-components/forms";
import "./sso-form.component.scss";

export const SsoForm = ({ setLoginMethod }) => {
  const [ssoLoading, setSSOLoading] = useState(false);
  const cleverIdpUrl = process.env.CLEVER_IDP_URL;
  const classlinkIdpUrl = process.env.CLASSLINK_IDP_URL;

  return ssoLoading ? (
    <p>Logging in...</p>
  ) : (
    <div className="sso-options">
      <Button
        className="login-button login-email"
        onClick={() => setLoginMethod("email")}
      >
        <img src="/favicon.svg" alt="Transfr Logo" aria-hidden />
        <span>Sign in with Username or Email</span>
      </Button>
      <div className="login-separator">
        <div />
        <div>OR</div>
        <div />
      </div>
      <Button
        className="login-button login-clever"
        onClick={() => {
          setSSOLoading(true);
          window.location.href = cleverIdpUrl;
        }}
      >
        <img src="/images/clever.svg" alt="Clever Logo" aria-hidden />
        <span>Sign in with Clever</span>
      </Button>
      <Button
        className="login-button login-classlink"
        onClick={() => {
          setSSOLoading(true);
          window.location.href = classlinkIdpUrl;
        }}
      >
        <img src="/images/classlink.svg" alt="Classlink Logo" aria-hidden />
        <span>Sign in with ClassLink</span>
      </Button>
      <Button
        className="login-button login-nydoe"
        onClick={() => {
          setSSOLoading(true);
          window.location.href = process.env.NYDOE_IDP_URL;
        }}
      >
        <img src="/images/nydoe.png" alt="NYCDOE Logo" aria-hidden />
        <span>Sign in with NYCDOE</span>
      </Button>
    </div>
  );
};
