import React from "react";

import { Switch, useRouteMatch } from "react-router-dom";
import { default as ActivityView } from "./activity-view";
import { default as ContainerRoute } from "../../components/common/routing/container-route";

export default function MyWork({ responsiveStyles = {} }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={path}
        component={ActivityView}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
    </Switch>
  );
}
